define("ember-responsive/services/media", ["exports", "ember", "@glimmer/tracking", "@ember/runloop", "@ember/object", "@ember/service", "@ember/string", "ember-responsive/null-match-media", "@ember/application", "@ember/object/evented", "@ember/object/compat"], function (_exports, _ember, _tracking, _runloop, _object, _service, _string, _nullMatchMedia, _application, _evented, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
  * Handles detecting and responding to media queries.
  *
  * **Adding media query matchers**
  *
  * The first step to using the class is to add media queries that you
  * want it to listen to. Each media query has a name that you can
  * use to reference it by.
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('mobile', '(max-width: 767px)');
  * media.match('desktop', '(min-width: 768px)');
  * ```
  *
  * **Testing the media query matchers**
  *
  * Now that you've added a few matchers, you can access those media queries as
  * if they were properties on your object. The nice thing is that whenever the
  * media queries change, this class will automatically update the relevant
  * properties (and so will the rest of your application, thanks to the power
  * of two-way data-binding).
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('mobile', '(max-width: 767px)');
  * media.match('desktop', '(min-width: 768px)');
  *
  * // There are convenient "isser" properties defined...
  * if (media.get('isMobile')) {
  *   console.log('mobile!');
  * }
  *
  * // As well as access to the matchMedia API...
  * if (media.get('desktop.matches')) {
  *   console.log('desktop!');
  * }
  * ```
  *
  * **Retrieving a list of matching media queries**
  *
  * It's also nice to be able to see which media queries are matching, since
  * some applications might have many matches at the same time.
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('desktop', 'all');
  * media.match('mobile', 'all');
  *
  * console.log(media.matches);
  * // => Ember.Set(['desktop', 'mobile']);
  * ```
  *
  * This class can also return that list as a string of dasherized class names,
  * which is useful for placing on your app's rootElement. By default, these
  * class names are prefixed with `media-`, so as not to clash with any other
  * classes your app might use.
  *
  * ```javascript
  * App.ApplicationView = Ember.View.extend({
  *   classNameBindings: ['media.classNames']
  * });
  * ```
  *
  * @module    ember-responsive
  * @namespace Ember.Responsive
  * @class     Media
  * @extends   Ember.Object
  */
  let MediaService = _exports.default = (_class = class MediaService extends _service.default.extend(_evented.default) {
    constructor(...args) {
      super(...args);
      // Ember only sets Ember.testing when tests are starting
      // eslint-disable-next-line ember/no-ember-testing-in-module-scope
      _defineProperty(this, "_mocked", _ember.default.testing);
      _defineProperty(this, "_mockedBreakpoint", 'desktop');
      /**
      * @property  _matches
      * @type      Array
      */
      _initializerDefineProperty(this, "_matches", _descriptor, this);
      /**
      * A hash of listeners indexed by their matcher's names
      *
      * @property
      * @type Object
      */
      _defineProperty(this, "listeners", {});
      /**
       * A hash of matchers by breakpoint name
       */
      _defineProperty(this, "matchers", {});
      /**
      * The matcher to use for testing media queries.
      *
      * @property  matcher
      * @type      matchMedia
      * @default   window.matchMedia
      * @private
      */
      _defineProperty(this, "mql", detectMatchMedia());
    }
    /**
    * A set of matching matchers.
    *
    * @property  matches
    * @type      Array
    */
    get matches() {
      if (this._matches) {
        return this._matches;
      }
      return _ember.default.testing && this._mocked ? [this._mockedBreakpoint] : [];
    }
    set matches(value) {
      this._matches = value;
    }
    /**
     * Initialize the service based on the breakpoints config
     *
     * @method init
     *
     */
    init() {
      super.init(...arguments);
      const breakpoints = (0, _application.getOwner)(this).lookup('breakpoints:main');
      if (breakpoints) {
        Object.keys(breakpoints).forEach(name => {
          const cpName = `is${(0, _string.classify)(name)}`;
          (0, _object.defineProperty)(this, cpName, (0, _compat.dependentKeyCompat)({
            get() {
              return this.matches.indexOf(name) > -1;
            }
          }));
          (0, _object.defineProperty)(this, name, (0, _compat.dependentKeyCompat)({
            get() {
              return this[cpName];
            }
          }));
          this.match(name, breakpoints[name]);
        });
      }
    }

    /**
    * A string composed of all the matching matchers' names, turned into
    * friendly, dasherized class-names that are prefixed with `media-`.
    *
    * @property  classNames
    * @type      string
    */
    get classNames() {
      return this.matches.map(function (name) {
        return `media-${(0, _string.dasherize)(name)}`;
      }).join(' ');
    }
    _triggerMediaChanged() {
      this.trigger('mediaChanged', {});
    }
    _triggerEvent() {
      (0, _runloop.once)(this, this._triggerMediaChanged);
    }

    /**
    * Adds a new matcher to the list.
    *
    * After this method is called, you will be able to access the result
    * of the matcher as a property on this object.
    *
    * **Adding a new matcher**
    *
    * ```javascript
    * media = Ember.Responsive.Media.create();
    * media.match('all', 'all');
    * media.get('all');
    *   // => instanceof window.matchMedia
    * media.get('all.matches');
    *   // => true
    * ```
    *
    * @param   string  name   The name of the matcher
    * @param   string  query  The media query to match against
    * @method  match
    */
    match(name, query) {
      // see https://github.com/ember-cli/eslint-plugin-ember/pull/272
      if (_ember.default.testing && this._mocked) {
        return;
      }
      const mql = this.mql;
      const matcher = mql(query);
      const listener = matcher => {
        if (this.isDestroyed) {
          return;
        }
        (0, _object.set)(this, `matchers.${name}`, matcher);
        if (matcher.matches) {
          this.matches = Array.from(new Set([...this.matches, name]));
        } else {
          this.matches = Array.from(new Set(this.matches.filter(key => key !== name)));
        }
        this._triggerEvent();
      };
      this.listeners[name] = listener;
      if (matcher.addListener) {
        matcher.addListener(function (matcher) {
          (0, _runloop.run)(null, listener, matcher);
        });
      }
      listener(matcher);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_matches", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  function detectMatchMedia() {
    if (typeof window === 'object' && window.matchMedia) {
      return window.matchMedia;
    }
    return _nullMatchMedia.default;
  }
});