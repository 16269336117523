! function (t) {
	var e = {};

	function n(r) {
		if (e[r]) return e[r].exports;
		var i = e[r] = {
			i: r,
			l: !1,
			exports: {}
		};
		return t[r].call(i.exports, i, i.exports, n), i.l = !0, i.exports
	}
	n.m = t, n.c = e, n.d = function (t, e, r) {
		n.o(t, e) || Object.defineProperty(t, e, {
			configurable: !1,
			enumerable: !0,
			get: r
		})
	}, n.n = function (t) {
		var e = t && t.__esModule ? function () {
			return t["default"]
		} : function () {
			return t
		};
		return n.d(e, "a", e), e
	}, n.o = function (t, e) {
		return Object.prototype.hasOwnProperty.call(t, e)
	}, n.p = "", n(n.s = 19)
}([function (t, e, n) {
	"use strict";
	var r = n(10),
		i = L.Class.extend({
			includes: [r.a],
			options: {
				snappable: !0,
				snapDistance: 20,
				tooltips: !0,
				cursorMarker: !0,
				finishOnDoubleClick: !1,
				finishOn: null,
				allowSelfIntersection: !0,
				templineStyle: {},
				hintlineStyle: {
					color: "#3388ff",
					dashArray: "5,5"
				},
				markerStyle: {
					draggable: !0
				}
			},
			initialize: function (t) {
				var e = this;
				this._map = t, this.shapes = ["Marker", "Line", "Poly", "Rectangle", "Circle", "Cut"], this.shapes.forEach(function (t) {
					e[t] = new L.PM.Draw[t](e._map)
				})
			},
			setPathOptions: function (t) {
				this.options.pathOptions = t
			},
			getShapes: function () {
				return this.shapes
			},
			enable: function (t, e) {
				if (!t) throw new Error("Error: Please pass a shape as a parameter. Possible shapes are: ".concat(this.getShapes().join(",")));
				this.disable(), this[t].enable(e)
			},
			disable: function () {
				var t = this;
				this.shapes.forEach(function (e) {
					t[e].disable()
				})
			},
			addControls: function () {
				var t = this;
				this.shapes.forEach(function (e) {
					t[e].addButton()
				})
			}
		});
	e.a = i
}, function (t, e, n) {
	"use strict";
	var r = n(10),
		i = n(35),
		o = L.Class.extend({
			includes: [i.a, r.a],
			options: {
				snappable: !0,
				snapDistance: 20,
				allowSelfIntersection: !0,
				draggable: !0
			},
			isPolygon: function () {
				return this._layer instanceof L.Polygon
			}
		});
	e.a = o
}, function (t, e, n) {
	"use strict";

	function r(t, e, n) {
		void 0 === n && (n = {});
		var r = {
			type: "Feature"
		};
		return (0 === n.id || n.id) && (r.id = n.id), n.bbox && (r.bbox = n.bbox), r.properties = e || {}, r.geometry = t, r
	}

	function i(t, e, n) {
		return void 0 === n && (n = {}), r({
			type: "Point",
			coordinates: t
		}, e, n)
	}

	function o(t, e, n) {
		void 0 === n && (n = {});
		for (var i = 0, o = t; i < o.length; i++) {
			var a = o[i];
			if (a.length < 4) throw new Error("Each LinearRing of a Polygon must have 4 or more Positions.");
			for (var s = 0; s < a[a.length - 1].length; s++)
				if (a[a.length - 1][s] !== a[0][s]) throw new Error("First and last Position are not equivalent.")
		}
		return r({
			type: "Polygon",
			coordinates: t
		}, e, n)
	}

	function a(t, e, n) {
		if (void 0 === n && (n = {}), t.length < 2) throw new Error("coordinates must be an array of two or more positions");
		return r({
			type: "LineString",
			coordinates: t
		}, e, n)
	}

	function s(t, e) {
		void 0 === e && (e = {});
		var n = {
			type: "FeatureCollection"
		};
		return e.id && (n.id = e.id), e.bbox && (n.bbox = e.bbox), n.features = t, n
	}

	function l(t, e, n) {
		return void 0 === n && (n = {}), r({
			type: "MultiLineString",
			coordinates: t
		}, e, n)
	}

	function h(t, e, n) {
		return void 0 === n && (n = {}), r({
			type: "MultiPoint",
			coordinates: t
		}, e, n)
	}

	function u(t, e, n) {
		return void 0 === n && (n = {}), r({
			type: "MultiPolygon",
			coordinates: t
		}, e, n)
	}

	function c(t, n) {
		void 0 === n && (n = "kilometers");
		var r = e.factors[n];
		if (!r) throw new Error(n + " units is invalid");
		return t * r
	}

	function p(t, n) {
		void 0 === n && (n = "kilometers");
		var r = e.factors[n];
		if (!r) throw new Error(n + " units is invalid");
		return t / r
	}

	function f(t) {
		return 180 * (t % (2 * Math.PI)) / Math.PI
	}

	function d(t) {
		return !isNaN(t) && null !== t && !Array.isArray(t) && !/^\s*$/.test(t)
	}
	Object.defineProperty(e, "__esModule", {
		value: !0
	}), e.earthRadius = 6371008.8, e.factors = {
		centimeters: 100 * e.earthRadius,
		centimetres: 100 * e.earthRadius,
		degrees: e.earthRadius / 111325,
		feet: 3.28084 * e.earthRadius,
		inches: 39.37 * e.earthRadius,
		kilometers: e.earthRadius / 1e3,
		kilometres: e.earthRadius / 1e3,
		meters: e.earthRadius,
		metres: e.earthRadius,
		miles: e.earthRadius / 1609.344,
		millimeters: 1e3 * e.earthRadius,
		millimetres: 1e3 * e.earthRadius,
		nauticalmiles: e.earthRadius / 1852,
		radians: 1,
		yards: e.earthRadius / 1.0936
	}, e.unitsFactors = {
		centimeters: 100,
		centimetres: 100,
		degrees: 1 / 111325,
		feet: 3.28084,
		inches: 39.37,
		kilometers: .001,
		kilometres: .001,
		meters: 1,
		metres: 1,
		miles: 1 / 1609.344,
		millimeters: 1e3,
		millimetres: 1e3,
		nauticalmiles: 1 / 1852,
		radians: 1 / e.earthRadius,
		yards: 1 / 1.0936
	}, e.areaFactors = {
		acres: 247105e-9,
		centimeters: 1e4,
		centimetres: 1e4,
		feet: 10.763910417,
		inches: 1550.003100006,
		kilometers: 1e-6,
		kilometres: 1e-6,
		meters: 1,
		metres: 1,
		miles: 3.86e-7,
		millimeters: 1e6,
		millimetres: 1e6,
		yards: 1.195990046
	}, e.feature = r, e.geometry = function (t, e, n) {
		switch (void 0 === n && (n = {}), t) {
			case "Point":
				return i(e).geometry;
			case "LineString":
				return a(e).geometry;
			case "Polygon":
				return o(e).geometry;
			case "MultiPoint":
				return h(e).geometry;
			case "MultiLineString":
				return l(e).geometry;
			case "MultiPolygon":
				return u(e).geometry;
			default:
				throw new Error(t + " is invalid")
		}
	}, e.point = i, e.points = function (t, e, n) {
		return void 0 === n && (n = {}), s(t.map(function (t) {
			return i(t, e)
		}), n)
	}, e.polygon = o, e.polygons = function (t, e, n) {
		return void 0 === n && (n = {}), s(t.map(function (t) {
			return o(t, e)
		}), n)
	}, e.lineString = a, e.lineStrings = function (t, e, n) {
		return void 0 === n && (n = {}), s(t.map(function (t) {
			return a(t, e)
		}), n)
	}, e.featureCollection = s, e.multiLineString = l, e.multiPoint = h, e.multiPolygon = u, e.geometryCollection = function (t, e, n) {
		return void 0 === n && (n = {}), r({
			type: "GeometryCollection",
			geometries: t
		}, e, n)
	}, e.round = function (t, e) {
		if (void 0 === e && (e = 0), e && !(e >= 0)) throw new Error("precision must be a positive number");
		var n = Math.pow(10, e || 0);
		return Math.round(t * n) / n
	}, e.radiansToLength = c, e.lengthToRadians = p, e.lengthToDegrees = function (t, e) {
		return f(p(t, e))
	}, e.bearingToAzimuth = function (t) {
		var e = t % 360;
		return e < 0 && (e += 360), e
	}, e.radiansToDegrees = f, e.degreesToRadians = function (t) {
		return t % 360 * Math.PI / 180
	}, e.convertLength = function (t, e, n) {
		if (void 0 === e && (e = "kilometers"), void 0 === n && (n = "kilometers"), !(t >= 0)) throw new Error("length must be a positive number");
		return c(p(t, e), n)
	}, e.convertArea = function (t, n, r) {
		if (void 0 === n && (n = "meters"), void 0 === r && (r = "kilometers"), !(t >= 0)) throw new Error("area must be a positive number");
		var i = e.areaFactors[n];
		if (!i) throw new Error("invalid original units");
		var o = e.areaFactors[r];
		if (!o) throw new Error("invalid final units");
		return t / i * o
	}, e.isNumber = d, e.isObject = function (t) {
		return !!t && t.constructor === Object
	}, e.validateBBox = function (t) {
		if (!t) throw new Error("bbox is required");
		if (!Array.isArray(t)) throw new Error("bbox must be an Array");
		if (4 !== t.length && 6 !== t.length) throw new Error("bbox must be an Array of 4 or 6 numbers");
		t.forEach(function (t) {
			if (!d(t)) throw new Error("bbox must only contain numbers")
		})
	}, e.validateId = function (t) {
		if (!t) throw new Error("id is required");
		if (-1 === ["string", "number"].indexOf(typeof t)) throw new Error("id must be a number or a string")
	}, e.radians2degrees = function () {
		throw new Error("method has been renamed to `radiansToDegrees`")
	}, e.degrees2radians = function () {
		throw new Error("method has been renamed to `degreesToRadians`")
	}, e.distanceToDegrees = function () {
		throw new Error("method has been renamed to `lengthToDegrees`")
	}, e.distanceToRadians = function () {
		throw new Error("method has been renamed to `lengthToRadians`")
	}, e.radiansToDistance = function () {
		throw new Error("method has been renamed to `radiansToLength`")
	}, e.bearingToAngle = function () {
		throw new Error("method has been renamed to `bearingToAzimuth`")
	}, e.convertDistance = function () {
		throw new Error("method has been renamed to `convertLength`")
	}
}, function (t, e, n) {
	var r = n(17)(Object, "create");
	t.exports = r
}, function (t, e, n) {
	var r = n(68);
	t.exports = function (t, e) {
		for (var n = t.length; n--;)
			if (r(t[n][0], e)) return n;
		return -1
	}
}, function (t, e, n) {
	var r = n(74);
	t.exports = function (t, e) {
		var n = t.__data__;
		return r(e) ? n["string" == typeof e ? "string" : "hash"] : n.map
	}
}, function (t, e) {
	var n = Array.isArray;
	t.exports = n
}, function (t, e, n) {
	var r = n(16),
		i = n(47),
		o = "[object Symbol]";
	t.exports = function (t) {
		return "symbol" == typeof t || i(t) && r(t) == o
	}
}, function (t, e, n) {
	var r = n(9).Symbol;
	t.exports = r
}, function (t, e, n) {
	var r = n(43),
		i = "object" == typeof self && self && self.Object === Object && self,
		o = r || i || Function("return this")();
	t.exports = o
}, function (t, e, n) {
	"use strict";
	var r = n(11),
		i = {
			_initSnappableMarkers: function () {
				this.options.snapDistance = this.options.snapDistance || 30, this._assignEvents(this._markers), this._layer.off("pm:dragstart", this._unsnap, this), this._layer.on("pm:dragstart", this._unsnap, this)
			},
			_assignEvents: function (t) {
				var e = this;
				t.forEach(function (t) {
					Array.isArray(t) ? e._assignEvents(t) : (t.off("drag", e._handleSnapping, e), t.on("drag", e._handleSnapping, e), t.off("dragend", e._cleanupSnapping, e), t.on("dragend", e._cleanupSnapping, e))
				})
			},
			_unsnap: function () {
				delete this._snapLatLng
			},
			_cleanupSnapping: function () {
				delete this._snapList, this._map.off("pm:remove", this._handleSnapLayerRemoval, this), this.debugIndicatorLines && this.debugIndicatorLines.forEach(function (t) {
					t.remove()
				})
			},
			_handleSnapLayerRemoval: function (t) {
				var e = t.layer,
					n = this._snapList.findIndex(function (t) {
						return t._leaflet_id === e._leaflet_id
					});
				this._snapList.splice(n, 1)
			},
			_handleSnapping: function (t) {
				var e = this;
				if (t.originalEvent.altKey) return !1;
				if (this._snapList === undefined && this._createSnapList(t), this._snapList.length <= 0) return !1;
				var n, r = t.target,
					i = this._calcClosestLayer(r.getLatLng(), this._snapList),
					o = i.layer instanceof L.Marker || i.layer instanceof L.CircleMarker;
				n = o ? i.latlng : this._checkPrioritiySnapping(i);
				var a = this.options.snapDistance,
					s = {
						marker: r,
						snapLatLng: n,
						segment: i.segment,
						layer: this._layer,
						layerInteractedWith: i.layer,
						distance: i.distance
					};
				if (s.marker.fire("pm:snapdrag", s), this._layer.fire("pm:snapdrag", s), i.distance < a) {
					r.setLatLng(n), r._snapped = !0;
					var l = this._snapLatLng || {},
						h = n || {};
					l.lat === h.lat && l.lng === h.lng || (e._snapLatLng = n, r.fire("pm:snap", s), e._layer.fire("pm:snap", s))
				} else this._snapLatLng && (this._unsnap(s), r._snapped = !1, s.marker.fire("pm:unsnap", s), this._layer.fire("pm:unsnap", s));
				return !0
			},
			_checkPrioritiySnapping: function (t) {
				var e, n = this._map,
					i = t.segment[0],
					o = t.segment[1],
					a = t.latlng,
					s = this._getDistance(n, i, a),
					l = this._getDistance(n, o, a),
					h = s < l ? i : o,
					u = s < l ? s : l;
				if (this.options.snapMiddle) {
					var c = r.a.calcMiddleLatLng(n, i, o),
						p = this._getDistance(n, c, a);
					p < s && p < l && (h = c, u = p)
				}
				return e = u < this.options.snapDistance ? h : a, Object.assign({}, e)
			},
			_createSnapList: function () {
				var t = this,
					e = [],
					n = [],
					r = this._map;
				r.off("pm:remove", this._handleSnapLayerRemoval, this), r.on("pm:remove", this._handleSnapLayerRemoval, this), r.eachLayer(function (t) {
					if (t instanceof L.Polyline || t instanceof L.Marker || t instanceof L.CircleMarker) {
						e.push(t);
						var r = L.polyline([], {
							color: "red",
							pmIgnore: !0
						});
						n.push(r)
					}
				}), e = (e = (e = e.filter(function (e) {
					return t._layer !== e
				})).filter(function (t) {
					return t._latlng || t._latlngs && t._latlngs.length > 0
				})).filter(function (t) {
					return !t._pmTempLayer
				}), this._otherSnapLayers ? this._snapList = e.concat(this._otherSnapLayers) : this._snapList = e, this.debugIndicatorLines = n
			},
			_calcClosestLayer: function (t, e) {
				var n = this,
					r = {};
				return e.forEach(function (e, i) {
					var o = n._calcLayerDistances(t, e);
					n.debugIndicatorLines[i].setLatLngs([t, o.latlng]), (r.distance === undefined || o.distance < r.distance) && ((r = o).layer = e)
				}), r
			},
			_calcLayerDistances: function (t, e) {
				var n, r, i = this,
					o = this._map,
					a = e instanceof L.Marker || e instanceof L.CircleMarker,
					s = e instanceof L.Polygon,
					l = t,
					h = a ? e.getLatLng() : e.getLatLngs();
				if (a) return {
					latlng: Object.assign({}, h),
					distance: this._getDistance(o, h, l)
				};
				! function c(t) {
					t.forEach(function (e, a) {
						if (Array.isArray(e)) c(e);
						else {
							var h, u = e;
							h = s ? a + 1 === t.length ? 0 : a + 1 : a + 1 === t.length ? undefined : a + 1;
							var p = t[h];
							if (p) {
								var f = i._getDistanceToSegment(o, l, u, p);
								(r === undefined || f < r) && (r = f, n = [u, p])
							}
						}
					})
				}(h);
				var u = this._getClosestPointOnSegment(o, t, n[0], n[1]);
				return {
					latlng: Object.assign({}, u),
					segment: n,
					distance: r
				}
			},
			_getClosestPointOnSegment: function (t, e, n, r) {
				var i = t.getMaxZoom();
				i === Infinity && (i = t.getZoom());
				var o = t.project(e, i),
					a = t.project(n, i),
					s = t.project(r, i),
					l = L.LineUtil.closestPointOnSegment(o, a, s);
				return t.unproject(l, i)
			},
			_getDistanceToSegment: function (t, e, n, r) {
				var i = t.latLngToLayerPoint(e),
					o = t.latLngToLayerPoint(n),
					a = t.latLngToLayerPoint(r);
				return L.LineUtil.pointToSegmentDistance(i, o, a)
			},
			_getDistance: function (t, e, n) {
				return t.latLngToLayerPoint(e).distanceTo(t.latLngToLayerPoint(n))
			}
		};
	e.a = i
}, function (t, e, n) {
	"use strict";
	e.a = {
		calcMiddleLatLng: function (t, e, n) {
			var r = t.project(e),
				i = t.project(n);
			return t.unproject(r._add(i)._divideBy(2))
		}
	}
}, function (t, e, n) {
	"use strict";
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var r = n(2);

	function i(t, e, n, r, i, o, a, s) {
		var l, h, u, c, p = {
			x: null,
			y: null,
			onLine1: !1,
			onLine2: !1
		};
		return 0 === (l = (s - o) * (n - t) - (a - i) * (r - e)) ? null !== p.x && null !== p.y && p : (c = (n - t) * (h = e - o) - (r - e) * (u = t - i), h = ((a - i) * h - (s - o) * u) / l, u = c / l, p.x = t + h * (n - t), p.y = e + h * (r - e), h >= 0 && h <= 1 && (p.onLine1 = !0), u >= 0 && u <= 1 && (p.onLine2 = !0), !(!p.onLine1 || !p.onLine2) && [p.x, p.y])
	}
	e["default"] = function (t) {
		var e, n, o = {
			type: "FeatureCollection",
			features: []
		};
		if ("LineString" === (n = "Feature" === t.type ? t.geometry : t).type) e = [n.coordinates];
		else if ("MultiLineString" === n.type) e = n.coordinates;
		else if ("MultiPolygon" === n.type) e = [].concat.apply([], n.coordinates);
		else {
			if ("Polygon" !== n.type) throw new Error("Input must be a LineString, MultiLineString, Polygon, or MultiPolygon Feature or Geometry");
			e = n.coordinates
		}
		return e.forEach(function (t) {
			e.forEach(function (e) {
				for (var n = 0; n < t.length - 1; n++)
					for (var a = n; a < e.length - 1; a++) {
						if (t === e) {
							if (1 === Math.abs(n - a)) continue;
							if (0 === n && a === t.length - 2 && t[n][0] === t[t.length - 1][0] && t[n][1] === t[t.length - 1][1]) continue
						}
						var s = i(t[n][0], t[n][1], t[n + 1][0], t[n + 1][1], e[a][0], e[a][1], e[a + 1][0], e[a + 1][1]);
						s && o.features.push(r.point([s[0], s[1]]))
					}
			})
		}), o
	}
}, function (t, e, n) {
	"use strict";
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var r = n(2);
	e.getCoord = function (t) {
		if (!t) throw new Error("coord is required");
		if (!Array.isArray(t)) {
			if ("Feature" === t.type && null !== t.geometry && "Point" === t.geometry.type) return t.geometry.coordinates;
			if ("Point" === t.type) return t.coordinates
		}
		if (Array.isArray(t) && t.length >= 2 && !Array.isArray(t[0]) && !Array.isArray(t[1])) return t;
		throw new Error("coord must be GeoJSON Point or an Array of numbers")
	}, e.getCoords = function (t) {
		if (Array.isArray(t)) return t;
		if ("Feature" === t.type) {
			if (null !== t.geometry) return t.geometry.coordinates
		} else if (t.coordinates) return t.coordinates;
		throw new Error("coords must be GeoJSON Feature, Geometry Object or an Array")
	}, e.containsNumber = function i(t) {
		if (t.length > 1 && r.isNumber(t[0]) && r.isNumber(t[1])) return !0;
		if (Array.isArray(t[0]) && t[0].length) return i(t[0]);
		throw new Error("coordinates must only contain numbers")
	}, e.geojsonType = function (t, e, n) {
		if (!e || !n) throw new Error("type and name required");
		if (!t || t.type !== e) throw new Error("Invalid input to " + n + ": must be a " + e + ", given " + t.type)
	}, e.featureOf = function (t, e, n) {
		if (!t) throw new Error("No feature passed");
		if (!n) throw new Error(".featureOf() requires a name");
		if (!t || "Feature" !== t.type || !t.geometry) throw new Error("Invalid input to " + n + ", Feature with geometry required");
		if (!t.geometry || t.geometry.type !== e) throw new Error("Invalid input to " + n + ": must be a " + e + ", given " + t.geometry.type)
	}, e.collectionOf = function (t, e, n) {
		if (!t) throw new Error("No featureCollection passed");
		if (!n) throw new Error(".collectionOf() requires a name");
		if (!t || "FeatureCollection" !== t.type) throw new Error("Invalid input to " + n + ", FeatureCollection required");
		for (var r = 0, i = t.features; r < i.length; r++) {
			var o = i[r];
			if (!o || "Feature" !== o.type || !o.geometry) throw new Error("Invalid input to " + n + ", Feature with geometry required");
			if (!o.geometry || o.geometry.type !== e) throw new Error("Invalid input to " + n + ": must be a " + e + ", given " + o.geometry.type)
		}
	}, e.getGeom = function (t) {
		return "Feature" === t.type ? t.geometry : t
	}, e.getType = function (t, e) {
		return "FeatureCollection" === t.type ? "FeatureCollection" : "GeometryCollection" === t.type ? "GeometryCollection" : "Feature" === t.type && null !== t.geometry ? t.geometry.type : t.type
	}
}, function (t, e, n) {
	! function (t, n) {
		n(e)
	}(0, function (t) {
		"use strict";

		function e(t, e) {
			return t > e ? 1 : t < e ? -1 : 0
		}
		var n = function (t, n) {
				void 0 === t && (t = e), void 0 === n && (n = !1), this._compare = t, this._root = null, this._size = 0, this._noDuplicates = !!n
			},
			r = {
				size: {
					configurable: !0
				}
			};
		n.prototype.rotateLeft = function (t) {
			var e = t.right;
			e && (t.right = e.left, e.left && (e.left.parent = t), e.parent = t.parent), t.parent ? t === t.parent.left ? t.parent.left = e : t.parent.right = e : this._root = e, e && (e.left = t), t.parent = e
		}, n.prototype.rotateRight = function (t) {
			var e = t.left;
			e && (t.left = e.right, e.right && (e.right.parent = t), e.parent = t.parent), t.parent ? t === t.parent.left ? t.parent.left = e : t.parent.right = e : this._root = e, e && (e.right = t), t.parent = e
		}, n.prototype._splay = function (t) {
			for (; t.parent;) {
				var e = t.parent;
				e.parent ? e.left === t && e.parent.left === e ? (this.rotateRight(e.parent), this.rotateRight(e)) : e.right === t && e.parent.right === e ? (this.rotateLeft(e.parent), this.rotateLeft(e)) : e.left === t && e.parent.right === e ? (this.rotateRight(e), this.rotateLeft(e)) : (this.rotateLeft(e), this.rotateRight(e)) : e.left === t ? this.rotateRight(e) : this.rotateLeft(e)
			}
		}, n.prototype.splay = function (t) {
			for (var e, n, r, i, o; t.parent;)(n = (e = t.parent).parent) && n.parent ? ((r = n.parent).left === n ? r.left = t : r.right = t, t.parent = r) : (t.parent = null, this._root = t), i = t.left, o = t.right, t === e.left ? (n && (n.left === e ? (e.right ? (n.left = e.right, n.left.parent = n) : n.left = null, e.right = n, n.parent = e) : (i ? (n.right = i, i.parent = n) : n.right = null, t.left = n, n.parent = t)), o ? (e.left = o, o.parent = e) : e.left = null, t.right = e, e.parent = t) : (n && (n.right === e ? (e.left ? (n.right = e.left, n.right.parent = n) : n.right = null, e.left = n, n.parent = e) : (o ? (n.left = o, o.parent = n) : n.left = null, t.right = n, n.parent = t)), i ? (e.right = i, i.parent = e) : e.right = null, t.left = e, e.parent = t)
		}, n.prototype.replace = function (t, e) {
			t.parent ? t === t.parent.left ? t.parent.left = e : t.parent.right = e : this._root = e, e && (e.parent = t.parent)
		}, n.prototype.minNode = function (t) {
			if (void 0 === t && (t = this._root), t)
				for (; t.left;) t = t.left;
			return t
		}, n.prototype.maxNode = function (t) {
			if (void 0 === t && (t = this._root), t)
				for (; t.right;) t = t.right;
			return t
		}, n.prototype.insert = function (t, e) {
			var n = this._root,
				r = null,
				i = this._compare;
			if (this._noDuplicates)
				for (; n;) {
					if (r = n, 0 === i(n.key, t)) return;
					n = i(n.key, t) < 0 ? n.right : n.left
				} else
					for (; n;) r = n, n = i(n.key, t) < 0 ? n.right : n.left;
			return n = {
				key: t,
				data: e,
				left: null,
				right: null,
				parent: r
			}, r ? i(r.key, n.key) < 0 ? r.right = n : r.left = n : this._root = n, this.splay(n), this._size++, n
		}, n.prototype.find = function (t) {
			for (var e = this._root, n = this._compare; e;) {
				var r = n(e.key, t);
				if (r < 0) e = e.right;
				else {
					if (!(r > 0)) return e;
					e = e.left
				}
			}
			return null
		}, n.prototype.contains = function (t) {
			for (var e = this._root, n = this._compare; e;) {
				var r = n(t, e.key);
				if (0 === r) return !0;
				e = r < 0 ? e.left : e.right
			}
			return !1
		}, n.prototype.remove = function (t) {
			var e = this.find(t);
			if (!e) return !1;
			if (this.splay(e), e.left)
				if (e.right) {
					var n = this.minNode(e.right);
					n.parent !== e && (this.replace(n, n.right), n.right = e.right, n.right.parent = n), this.replace(e, n), n.left = e.left, n.left.parent = n
				} else this.replace(e, e.left);
			else this.replace(e, e.right);
			return this._size--, !0
		}, n.prototype.removeNode = function (t) {
			if (!t) return !1;
			if (this.splay(t), t.left)
				if (t.right) {
					var e = this.minNode(t.right);
					e.parent !== t && (this.replace(e, e.right), e.right = t.right, e.right.parent = e), this.replace(t, e), e.left = t.left, e.left.parent = e
				} else this.replace(t, t.left);
			else this.replace(t, t.right);
			return this._size--, !0
		}, n.prototype.erase = function (t) {
			var e = this.find(t);
			if (e) {
				this.splay(e);
				var n = e.left,
					r = e.right,
					i = null;
				n && (n.parent = null, i = this.maxNode(n), this.splay(i), this._root = i), r && (n ? i.right = r : this._root = r, r.parent = i), this._size--
			}
		}, n.prototype.pop = function () {
			var t = this._root,
				e = null;
			if (t) {
				for (; t.left;) t = t.left;
				e = {
					key: t.key,
					data: t.data
				}, this.remove(t.key)
			}
			return e
		}, n.prototype.next = function (t) {
			var e = t;
			if (e)
				if (e.right)
					for (e = e.right; e && e.left;) e = e.left;
				else
					for (e = t.parent; e && e.right === t;) t = e, e = e.parent;
			return e
		}, n.prototype.prev = function (t) {
			var e = t;
			if (e)
				if (e.left)
					for (e = e.left; e && e.right;) e = e.right;
				else
					for (e = t.parent; e && e.left === t;) t = e, e = e.parent;
			return e
		}, n.prototype.forEach = function (t) {
			for (var e = this._root, n = [], r = !1, i = 0; !r;) e ? (n.push(e), e = e.left) : n.length > 0 ? (t(e = n.pop(), i++), e = e.right) : r = !0;
			return this
		}, n.prototype.range = function (t, e, n, r) {
			for (var i = [], o = this._compare, a = this._root; 0 !== i.length || a;)
				if (a) i.push(a), a = a.left;
				else {
					if (o((a = i.pop()).key, e) > 0) break;
					if (o(a.key, t) >= 0 && n.call(r, a)) return this;
					a = a.right
				}
			return this
		}, n.prototype.keys = function () {
			for (var t = this._root, e = [], n = [], r = !1; !r;) t ? (e.push(t), t = t.left) : e.length > 0 ? (t = e.pop(), n.push(t.key), t = t.right) : r = !0;
			return n
		}, n.prototype.values = function () {
			for (var t = this._root, e = [], n = [], r = !1; !r;) t ? (e.push(t), t = t.left) : e.length > 0 ? (t = e.pop(), n.push(t.data), t = t.right) : r = !0;
			return n
		}, n.prototype.at = function (t) {
			for (var e = this._root, n = [], r = !1, i = 0; !r;)
				if (e) n.push(e), e = e.left;
				else if (n.length > 0) {
				if (e = n.pop(), i === t) return e;
				i++, e = e.right
			} else r = !0;
			return null
		}, n.prototype.load = function (t, e, n) {
			if (void 0 === t && (t = []), void 0 === e && (e = []), void 0 === n && (n = !1), 0 !== this._size) throw new Error("bulk-load: tree is not empty");
			var r = t.length;
			return n && function i(t, e, n, r, o) {
				if (n >= r) return;
				var a = t[n + r >> 1];
				var s = n - 1;
				var l = r + 1;
				for (;;) {
					do {
						s++
					} while (o(t[s], a) < 0);
					do {
						l--
					} while (o(t[l], a) > 0);
					if (s >= l) break;
					var h = t[s];
					t[s] = t[l], t[l] = h, h = e[s], e[s] = e[l], e[l] = h
				}
				i(t, e, n, l, o);
				i(t, e, l + 1, r, o)
			}(t, e, 0, r - 1, this._compare), this._root = function o(t, e, n, r, i) {
				var a = i - r;
				if (a > 0) {
					var s = r + Math.floor(a / 2),
						l = e[s],
						h = n[s],
						u = {
							key: l,
							data: h,
							parent: t
						};
					return u.left = o(u, e, n, r, s), u.right = o(u, e, n, s + 1, i), u
				}
				return null
			}(null, t, e, 0, r), this._size = r, this
		}, n.prototype.min = function () {
			var t = this.minNode(this._root);
			return t ? t.key : null
		}, n.prototype.max = function () {
			var t = this.maxNode(this._root);
			return t ? t.key : null
		}, n.prototype.isEmpty = function () {
			return null === this._root
		}, r.size.get = function () {
			return this._size
		}, n.createTree = function (t, e, r, i, o) {
			return new n(r, o).load(t, e, i)
		}, Object.defineProperties(n.prototype, r);
		var i = 0,
			o = 1,
			a = 2,
			s = 3,
			l = 0,
			h = 1,
			u = 2,
			c = 3;

		function p(t, e, n) {
			null === e ? (t.inOut = !1, t.otherInOut = !0) : (t.isSubject === e.isSubject ? (t.inOut = !e.inOut, t.otherInOut = e.otherInOut) : (t.inOut = !e.otherInOut, t.otherInOut = e.isVertical() ? !e.inOut : e.inOut), e && (t.prevInResult = !f(e, n) || e.isVertical() ? e.prevInResult : e)), t.inResult = f(t, n)
		}

		function f(t, e) {
			switch (t.type) {
				case i:
					switch (e) {
						case l:
							return !t.otherInOut;
						case h:
							return t.otherInOut;
						case u:
							return t.isSubject && t.otherInOut || !t.isSubject && !t.otherInOut;
						case c:
							return !0
					}
					break;
				case a:
					return e === l || e === h;
				case s:
					return e === u;
				case o:
					return !1
			}
			return !1
		}
		var d = function (t, e, n, r, o) {
			this.left = e, this.point = t, this.otherEvent = n, this.isSubject = r, this.type = o || i, this.inOut = !1, this.otherInOut = !1, this.prevInResult = null, this.inResult = !1, this.resultInOut = !1, this.isExteriorRing = !0
		};

		function g(t, e) {
			return t[0] === e[0] && t[1] === e[1]
		}

		function _(t, e, n) {
			return (t[0] - n[0]) * (e[1] - n[1]) - (e[0] - n[0]) * (t[1] - n[1])
		}

		function m(t, e) {
			var n = t.point,
				r = e.point;
			return n[0] > r[0] ? 1 : n[0] < r[0] ? -1 : n[1] !== r[1] ? n[1] > r[1] ? 1 : -1 : function (t, e, n, r) {
				if (t.left !== e.left) return t.left ? 1 : -1;
				if (0 !== _(n, t.otherEvent.point, e.otherEvent.point)) return t.isBelow(e.otherEvent.point) ? -1 : 1;
				return !t.isSubject && e.isSubject ? 1 : -1
			}(t, e, n)
		}

		function y(t, e, n) {
			var r = new d(e, !1, t, t.isSubject),
				i = new d(e, !0, t.otherEvent, t.isSubject);
			return g(t.point, t.otherEvent.point) && console.warn("what is that, a collapsed segment?", t), r.contourId = i.contourId = t.contourId, m(i, t.otherEvent) > 0 && (t.otherEvent.left = !0, i.left = !1), t.otherEvent.otherEvent = i, t.otherEvent = r, n.push(i), n.push(r), n
		}

		function v(t, e) {
			return t[0] * e[1] - t[1] * e[0]
		}

		function L(t, e) {
			return t[0] * e[0] + t[1] * e[1]
		}

		function b(t, e, n) {
			var r = function (t, e, n, r, i) {
					var o = [e[0] - t[0], e[1] - t[1]],
						a = [r[0] - n[0], r[1] - n[1]];

					function s(t, e, n) {
						return [t[0] + e * n[0], t[1] + e * n[1]]
					}
					var l = [n[0] - t[0], n[1] - t[1]],
						h = v(o, a),
						u = h * h,
						c = L(o, o);
					if (u > 0) {
						var p = v(l, a) / h;
						if (p < 0 || p > 1) return null;
						var f = v(l, o) / h;
						return f < 0 || f > 1 ? null : 0 === p || 1 === p ? i ? null : [s(t, p, o)] : 0 === f || 1 === f ? i ? null : [s(n, f, a)] : [s(t, p, o)]
					}
					if ((u = (h = v(l, o)) * h) > 0) return null;
					var d = L(o, l) / c,
						g = d + L(o, a) / c,
						_ = Math.min(d, g),
						m = Math.max(d, g);
					return _ <= 1 && m >= 0 ? 1 === _ ? i ? null : [s(t, _ > 0 ? _ : 0, o)] : 0 === m ? i ? null : [s(t, m < 1 ? m : 1, o)] : i && 0 === _ && 1 === m ? null : [s(t, _ > 0 ? _ : 0, o), s(t, m < 1 ? m : 1, o)] : null
				}(t.point, t.otherEvent.point, e.point, e.otherEvent.point),
				i = r ? r.length : 0;
			if (0 === i) return 0;
			if (1 === i && (g(t.point, e.point) || g(t.otherEvent.point, e.otherEvent.point))) return 0;
			if (2 === i && t.isSubject === e.isSubject) return 0;
			if (1 === i) return g(t.point, r[0]) || g(t.otherEvent.point, r[0]) || y(t, r[0], n), g(e.point, r[0]) || g(e.otherEvent.point, r[0]) || y(e, r[0], n), 1;
			var l = [],
				h = !1,
				u = !1;
			return g(t.point, e.point) ? h = !0 : 1 === m(t, e) ? l.push(e, t) : l.push(t, e), g(t.otherEvent.point, e.otherEvent.point) ? u = !0 : 1 === m(t.otherEvent, e.otherEvent) ? l.push(e.otherEvent, t.otherEvent) : l.push(t.otherEvent, e.otherEvent), h && u || h ? (e.type = o, t.type = e.inOut === t.inOut ? a : s, h && !u && y(l[1].otherEvent, l[0].point, n), 2) : u ? (y(l[0], l[1].point, n), 3) : l[0] !== l[3].otherEvent ? (y(l[0], l[1].point, n), y(l[1], l[2].point, n), 3) : (y(l[0], l[1].point, n), y(l[3].otherEvent, l[2].point, n), 3)
		}

		function k(t, e) {
			if (t === e) return 0;
			if (0 !== _(t.point, t.otherEvent.point, e.point) || 0 !== _(t.point, t.otherEvent.point, e.otherEvent.point)) return g(t.point, e.point) ? t.isBelow(e.otherEvent.point) ? -1 : 1 : t.point[0] === e.point[0] ? t.point[1] < e.point[1] ? -1 : 1 : 1 === m(t, e) ? e.isAbove(t.point) ? -1 : 1 : t.isBelow(e.point) ? -1 : 1;
			if (t.isSubject !== e.isSubject) return t.isSubject ? -1 : 1;
			var n = t.point,
				r = e.point;
			return n[0] === r[0] && n[1] === r[1] ? (n = t.otherEvent.point, r = e.otherEvent.point, n[0] === r[0] && n[1] === r[1] ? 0 : t.contourId > e.contourId ? 1 : -1) : 1 === m(t, e) ? 1 : -1
		}

		function M(t, e, n, r) {
			var i = t + 1,
				o = e.length;
			if (i > o - 1) return t - 1;
			for (var a = e[t].point, s = e[i].point; i < o && s[0] === a[0] && s[1] === a[1];) {
				if (!n[i]) return i;
				s = e[++i].point
			}
			for (i = t - 1; n[i] && i >= r;) i--;
			return i
		}

		function w(t, e) {
			var n, r, i, o = function (t) {
					var e, n, r, i, o = [];
					for (n = 0, r = t.length; n < r; n++)((e = t[n]).left && e.inResult || !e.left && e.otherEvent.inResult) && o.push(e);
					for (var a = !1; !a;)
						for (a = !0, n = 0, r = o.length; n < r; n++) n + 1 < r && 1 === m(o[n], o[n + 1]) && (i = o[n], o[n] = o[n + 1], o[n + 1] = i, a = !1);
					for (n = 0, r = o.length; n < r; n++)(e = o[n]).pos = n;
					for (n = 0, r = o.length; n < r; n++)(e = o[n]).left || (i = e.pos, e.pos = e.otherEvent.pos, e.otherEvent.pos = i);
					return o
				}(t),
				a = {},
				s = [];
			for (n = 0, r = o.length; n < r; n++)
				if (!a[n]) {
					var l = [
						[]
					];
					o[n].isExteriorRing ? e === u && !o[n].isSubject && s.length > 1 ? s[s.length - 1].push(l[0]) : s.push(l) : e !== u || o[n].isSubject || 0 !== s.length ? 0 === s.length ? s.push([
						[l]
					]) : s[s.length - 1].push(l[0]) : s.push(l);
					var h = s.length - 1,
						c = n,
						p = o[n].point;
					for (l[0].push(p); c >= n;) i = o[c], a[c] = !0, i.left ? (i.resultInOut = !1, i.contourId = h) : (i.otherEvent.resultInOut = !0, i.otherEvent.contourId = h), a[c = i.pos] = !0, l[0].push(o[c].point), c = M(c, o, a, n);
					i = o[c = -1 === c ? n : c], a[c] = a[i.pos] = !0, i.otherEvent.resultInOut = !0, i.otherEvent.contourId = h
				}
			return s
		}
		d.prototype.isBelow = function (t) {
			var e = this.point,
				n = this.otherEvent.point;
			return this.left ? (e[0] - t[0]) * (n[1] - t[1]) - (n[0] - t[0]) * (e[1] - t[1]) > 0 : (n[0] - t[0]) * (e[1] - t[1]) - (e[0] - t[0]) * (n[1] - t[1]) > 0
		}, d.prototype.isAbove = function (t) {
			return !this.isBelow(t)
		}, d.prototype.isVertical = function () {
			return this.point[0] === this.otherEvent.point[0]
		}, d.prototype.clone = function () {
			var t = new d(this.point, this.left, this.otherEvent, this.isSubject, this.type);
			return t.inResult = this.inResult, t.prevInResult = this.prevInResult, t.isExteriorRing = this.isExteriorRing, t.inOut = this.inOut, t.otherInOut = this.otherInOut, t
		};
		var E = C,
			S = C;

		function C(t, e) {
			if (!(this instanceof C)) return new C(t, e);
			if (this.data = t || [], this.length = this.data.length, this.compare = e || x, this.length > 0)
				for (var n = (this.length >> 1) - 1; n >= 0; n--) this._down(n)
		}

		function x(t, e) {
			return t < e ? -1 : t > e ? 1 : 0
		}
		C.prototype = {
			push: function (t) {
				this.data.push(t), this.length++, this._up(this.length - 1)
			},
			pop: function () {
				if (0 === this.length) return undefined;
				var t = this.data[0];
				return this.length--, this.length > 0 && (this.data[0] = this.data[this.length], this._down(0)), this.data.pop(), t
			},
			peek: function () {
				return this.data[0]
			},
			_up: function (t) {
				for (var e = this.data, n = this.compare, r = e[t]; t > 0;) {
					var i = t - 1 >> 1,
						o = e[i];
					if (n(r, o) >= 0) break;
					e[t] = o, t = i
				}
				e[t] = r
			},
			_down: function (t) {
				for (var e = this.data, n = this.compare, r = this.length >> 1, i = e[t]; t < r;) {
					var o = 1 + (t << 1),
						a = o + 1,
						s = e[o];
					if (a < this.length && n(e[a], s) < 0 && (o = a, s = e[a]), n(s, i) >= 0) break;
					e[t] = s, t = o
				}
				e[t] = i
			}
		}, E["default"] = S;
		var P = Math.max,
			O = Math.min,
			I = 0;

		function T(t, e, n, r, i, o) {
			var a, s, l, h, u, c;
			for (a = 0, s = t.length - 1; a < s; a++)
				if (l = t[a], h = t[a + 1], u = new d(l, !1, undefined, e), c = new d(h, !1, u, e), u.otherEvent = c, l[0] !== h[0] || l[1] !== h[1]) {
					u.contourId = c.contourId = n, o || (u.isExteriorRing = !1, c.isExteriorRing = !1), m(u, c) > 0 ? c.left = !0 : u.left = !0;
					var p = l[0],
						f = l[1];
					i[0] = O(i[0], p), i[1] = O(i[1], f), i[2] = P(i[2], p), i[3] = P(i[3], f), r.push(u), r.push(c)
				}
		}
		var D = [];

		function j(t, e, r) {
			"number" == typeof t[0][0][0] && (t = [t]), "number" == typeof e[0][0][0] && (e = [e]);
			var i = function (t, e, n) {
				var r = null;
				return t.length * e.length == 0 && (n === l ? r = D : n === u ? r = t : n !== h && n !== c || (r = 0 === t.length ? e : t)), r
			}(t, e, r);
			if (i) return i === D ? null : i;
			var o = [Infinity, Infinity, -Infinity, -Infinity],
				a = [Infinity, Infinity, -Infinity, -Infinity],
				s = function (t, e, n, r, i) {
					var o, a, s, l, h, c, p = new E(null, m);
					for (s = 0, l = t.length; s < l; s++)
						for (h = 0, c = (o = t[s]).length; h < c; h++)(a = 0 === h) && I++, T(o[h], !0, I, p, n, a);
					for (s = 0, l = e.length; s < l; s++)
						for (h = 0, c = (o = e[s]).length; h < c; h++) a = 0 === h, i === u && (a = !1), a && I++, T(o[h], !1, I, p, r, a);
					return p
				}(t, e, o, a, r);
			return (i = function (t, e, n, r, i) {
				var o = null;
				return (n[0] > r[2] || r[0] > n[2] || n[1] > r[3] || r[1] > n[3]) && (i === l ? o = D : i === u ? o = t : i !== h && i !== c || (o = t.concat(e))), o
			}(t, e, o, a, r)) ? i === D ? null : i : w(function (t, e, r, i, o, a) {
				for (var s, h, c, f = new n(k), d = [], g = Math.min(i[2], o[2]); 0 !== t.length;) {
					var _ = t.pop();
					if (d.push(_), a === l && _.point[0] > g || a === u && _.point[0] > i[2]) break;
					if (_.left) {
						h = s = f.insert(_), s = s !== (c = f.minNode()) ? f.prev(s) : null, h = f.next(h);
						var m = s ? s.key : null;
						if (p(_, m, a), h && 2 === b(_, h.key, t) && (p(_, m, a), p(_, h.key, a)), s && 2 === b(s.key, _, t)) {
							var y = s;
							p(m, (y = y !== c ? f.prev(y) : null) ? y.key : null, a), p(_, m, a)
						}
					} else _ = _.otherEvent, h = s = f.find(_), s && h && (s = s !== c ? f.prev(s) : null, h = f.next(h), f.remove(_), h && s && b(s.key, h.key, t))
				}
				return d
			}(s, 0, 0, o, a, r), r)
		}
		var R = {
			UNION: h,
			DIFFERENCE: u,
			INTERSECTION: l,
			XOR: c
		};
		t.union = function (t, e) {
			return j(t, e, h)
		}, t.diff = function (t, e) {
			return j(t, e, u)
		}, t.xor = function (t, e) {
			return j(t, e, c)
		}, t.intersection = function (t, e) {
			return j(t, e, l)
		}, t.operations = R, Object.defineProperty(t, "__esModule", {
			value: !0
		})
	})
}, function (t, e, n) {
	"use strict";
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var r = n(2);

	function i(t, e, n) {
		if (null !== t)
			for (var r, o, a, s, l, h, u, c, p = 0, f = 0, d = t.type, g = "FeatureCollection" === d, _ = "Feature" === d, m = g ? t.features.length : 1, y = 0; y < m; y++) {
				l = (c = !!(u = g ? t.features[y].geometry : _ ? t.geometry : t) && "GeometryCollection" === u.type) ? u.geometries.length : 1;
				for (var v = 0; v < l; v++) {
					var L = 0,
						b = 0;
					if (null !== (s = c ? u.geometries[v] : u)) {
						h = s.coordinates;
						var k = s.type;
						switch (p = !n || "Polygon" !== k && "MultiPolygon" !== k ? 0 : 1, k) {
							case null:
								break;
							case "Point":
								if (!1 === e(h, f, y, L, b)) return !1;
								f++, L++;
								break;
							case "LineString":
							case "MultiPoint":
								for (r = 0; r < h.length; r++) {
									if (!1 === e(h[r], f, y, L, b)) return !1;
									f++, "MultiPoint" === k && L++
								}
								"LineString" === k && L++;
								break;
							case "Polygon":
							case "MultiLineString":
								for (r = 0; r < h.length; r++) {
									for (o = 0; o < h[r].length - p; o++) {
										if (!1 === e(h[r][o], f, y, L, b)) return !1;
										f++
									}
									"MultiLineString" === k && L++, "Polygon" === k && b++
								}
								"Polygon" === k && L++;
								break;
							case "MultiPolygon":
								for (r = 0; r < h.length; r++) {
									for (b = 0, o = 0; o < h[r].length; o++) {
										for (a = 0; a < h[r][o].length - p; a++) {
											if (!1 === e(h[r][o][a], f, y, L, b)) return !1;
											f++
										}
										b++
									}
									L++
								}
								break;
							case "GeometryCollection":
								for (r = 0; r < s.geometries.length; r++)
									if (!1 === i(s.geometries[r], e, n)) return !1;
								break;
							default:
								throw new Error("Unknown Geometry Type")
						}
					}
				}
			}
	}

	function o(t, e) {
		var n;
		switch (t.type) {
			case "FeatureCollection":
				for (n = 0; n < t.features.length && !1 !== e(t.features[n].properties, n); n++);
				break;
			case "Feature":
				e(t.properties, 0)
		}
	}

	function a(t, e) {
		if ("Feature" === t.type) e(t, 0);
		else if ("FeatureCollection" === t.type)
			for (var n = 0; n < t.features.length && !1 !== e(t.features[n], n); n++);
	}

	function s(t, e) {
		var n, r, i, o, a, s, l, h, u, c, p = 0,
			f = "FeatureCollection" === t.type,
			d = "Feature" === t.type,
			g = f ? t.features.length : 1;
		for (n = 0; n < g; n++) {
			for (s = f ? t.features[n].geometry : d ? t.geometry : t, h = f ? t.features[n].properties : d ? t.properties : {}, u = f ? t.features[n].bbox : d ? t.bbox : undefined, c = f ? t.features[n].id : d ? t.id : undefined, a = (l = !!s && "GeometryCollection" === s.type) ? s.geometries.length : 1, i = 0; i < a; i++)
				if (null !== (o = l ? s.geometries[i] : s)) switch (o.type) {
					case "Point":
					case "LineString":
					case "MultiPoint":
					case "Polygon":
					case "MultiLineString":
					case "MultiPolygon":
						if (!1 === e(o, p, h, u, c)) return !1;
						break;
					case "GeometryCollection":
						for (r = 0; r < o.geometries.length; r++)
							if (!1 === e(o.geometries[r], p, h, u, c)) return !1;
						break;
					default:
						throw new Error("Unknown Geometry Type")
				} else if (!1 === e(null, p, h, u, c)) return !1;
			p++
		}
	}

	function l(t, e) {
		s(t, function (t, n, i, o, a) {
			var s, l = null === t ? null : t.type;
			switch (l) {
				case null:
				case "Point":
				case "LineString":
				case "Polygon":
					return !1 !== e(r.feature(t, i, {
						bbox: o,
						id: a
					}), n, 0) && void 0
			}
			switch (l) {
				case "MultiPoint":
					s = "Point";
					break;
				case "MultiLineString":
					s = "LineString";
					break;
				case "MultiPolygon":
					s = "Polygon"
			}
			for (var h = 0; h < t.coordinates.length; h++) {
				var u = {
					type: s,
					coordinates: t.coordinates[h]
				};
				if (!1 === e(r.feature(u, i), n, h)) return !1
			}
		})
	}

	function h(t, e) {
		l(t, function (t, n, o) {
			var a = 0;
			if (t.geometry) {
				var s = t.geometry.type;
				if ("Point" !== s && "MultiPoint" !== s) {
					var l, h = 0,
						u = 0,
						c = 0;
					return !1 !== i(t, function (i, s, p, f, d) {
						if (l === undefined || n > h || f > u || d > c) return l = i, h = n, u = f, c = d, void(a = 0);
						var g = r.lineString([l, i], t.properties);
						if (!1 === e(g, n, o, d, a)) return !1;
						a++, l = i
					}) && void 0
				}
			}
		})
	}

	function u(t, e) {
		if (!t) throw new Error("geojson is required");
		l(t, function (t, n, i) {
			if (null !== t.geometry) {
				var o = t.geometry.type,
					a = t.geometry.coordinates;
				switch (o) {
					case "LineString":
						if (!1 === e(t, n, i, 0, 0)) return !1;
						break;
					case "Polygon":
						for (var s = 0; s < a.length; s++)
							if (!1 === e(r.lineString(a[s], t.properties), n, i, s)) return !1
				}
			}
		})
	}
	e.coordEach = i, e.coordReduce = function (t, e, n, r) {
		var o = n;
		return i(t, function (t, r, i, a, s) {
			o = 0 === r && n === undefined ? t : e(o, t, r, i, a, s)
		}, r), o
	}, e.propEach = o, e.propReduce = function (t, e, n) {
		var r = n;
		return o(t, function (t, i) {
			r = 0 === i && n === undefined ? t : e(r, t, i)
		}), r
	}, e.featureEach = a, e.featureReduce = function (t, e, n) {
		var r = n;
		return a(t, function (t, i) {
			r = 0 === i && n === undefined ? t : e(r, t, i)
		}), r
	}, e.coordAll = function (t) {
		var e = [];
		return i(t, function (t) {
			e.push(t)
		}), e
	}, e.geomEach = s, e.geomReduce = function (t, e, n) {
		var r = n;
		return s(t, function (t, i, o, a, s) {
			r = 0 === i && n === undefined ? t : e(r, t, i, o, a, s)
		}), r
	}, e.flattenEach = l, e.flattenReduce = function (t, e, n) {
		var r = n;
		return l(t, function (t, i, o) {
			r = 0 === i && 0 === o && n === undefined ? t : e(r, t, i, o)
		}), r
	}, e.segmentEach = h, e.segmentReduce = function (t, e, n) {
		var r = n,
			i = !1;
		return h(t, function (t, o, a, s, l) {
			r = !1 === i && n === undefined ? t : e(r, t, o, a, s, l), i = !0
		}), r
	}, e.lineEach = u, e.lineReduce = function (t, e, n) {
		var r = n;
		return u(t, function (t, i, o, a) {
			r = 0 === i && n === undefined ? t : e(r, t, i, o, a)
		}), r
	}, e.findSegment = function (t, e) {
		if (e = e || {}, !r.isObject(e)) throw new Error("options is invalid");
		var n, i = e.featureIndex || 0,
			o = e.multiFeatureIndex || 0,
			a = e.geometryIndex || 0,
			s = e.segmentIndex || 0,
			l = e.properties;
		switch (t.type) {
			case "FeatureCollection":
				i < 0 && (i = t.features.length + i), l = l || t.features[i].properties, n = t.features[i].geometry;
				break;
			case "Feature":
				l = l || t.properties, n = t.geometry;
				break;
			case "Point":
			case "MultiPoint":
				return null;
			case "LineString":
			case "Polygon":
			case "MultiLineString":
			case "MultiPolygon":
				n = t;
				break;
			default:
				throw new Error("geojson is invalid")
		}
		if (null === n) return null;
		var h = n.coordinates;
		switch (n.type) {
			case "Point":
			case "MultiPoint":
				return null;
			case "LineString":
				return s < 0 && (s = h.length + s - 1), r.lineString([h[s], h[s + 1]], l, e);
			case "Polygon":
				return a < 0 && (a = h.length + a), s < 0 && (s = h[a].length + s - 1), r.lineString([h[a][s], h[a][s + 1]], l, e);
			case "MultiLineString":
				return o < 0 && (o = h.length + o), s < 0 && (s = h[o].length + s - 1), r.lineString([h[o][s], h[o][s + 1]], l, e);
			case "MultiPolygon":
				return o < 0 && (o = h.length + o), a < 0 && (a = h[o].length + a), s < 0 && (s = h[o][a].length - s - 1), r.lineString([h[o][a][s], h[o][a][s + 1]], l, e)
		}
		throw new Error("geojson is invalid")
	}, e.findPoint = function (t, e) {
		if (e = e || {}, !r.isObject(e)) throw new Error("options is invalid");
		var n, i = e.featureIndex || 0,
			o = e.multiFeatureIndex || 0,
			a = e.geometryIndex || 0,
			s = e.coordIndex || 0,
			l = e.properties;
		switch (t.type) {
			case "FeatureCollection":
				i < 0 && (i = t.features.length + i), l = l || t.features[i].properties, n = t.features[i].geometry;
				break;
			case "Feature":
				l = l || t.properties, n = t.geometry;
				break;
			case "Point":
			case "MultiPoint":
				return null;
			case "LineString":
			case "Polygon":
			case "MultiLineString":
			case "MultiPolygon":
				n = t;
				break;
			default:
				throw new Error("geojson is invalid")
		}
		if (null === n) return null;
		var h = n.coordinates;
		switch (n.type) {
			case "Point":
				return r.point(h, l, e);
			case "MultiPoint":
				return o < 0 && (o = h.length + o), r.point(h[o], l, e);
			case "LineString":
				return s < 0 && (s = h.length + s), r.point(h[s], l, e);
			case "Polygon":
				return a < 0 && (a = h.length + a), s < 0 && (s = h[a].length + s), r.point(h[a][s], l, e);
			case "MultiLineString":
				return o < 0 && (o = h.length + o), s < 0 && (s = h[o].length + s), r.point(h[o][s], l, e);
			case "MultiPolygon":
				return o < 0 && (o = h.length + o), a < 0 && (a = h[o].length + a), s < 0 && (s = h[o][a].length - s), r.point(h[o][a][s], l, e)
		}
		throw new Error("geojson is invalid")
	}
}, function (t, e, n) {
	var r = n(8),
		i = n(45),
		o = n(46),
		a = "[object Null]",
		s = "[object Undefined]",
		l = r ? r.toStringTag : undefined;
	t.exports = function (t) {
		return null == t ? t === undefined ? s : a : l && l in Object(t) ? i(t) : o(t)
	}
}, function (t, e, n) {
	var r = n(55),
		i = n(60);
	t.exports = function (t, e) {
		var n = i(t, e);
		return r(n) ? n : undefined
	}
}, function (t, e) {
	t.exports = function (t) {
		var e = typeof t;
		return null != t && ("object" == e || "function" == e)
	}
}, function (t, e, n) {
	t.exports = n(20)
}, function (t, e, n) {
	"use strict";
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var r = n(21),
		i = (n.n(r), n(22)),
		o = (n.n(i), n(23)),
		a = n(24),
		s = n(0),
		l = (n(26), n(27), n(28), n(29), n(30), n(31), n(1)),
		h = (n(36), n(37), n(38), n(82), n(83), n(84), n(85)),
		u = (n.n(h), n(86));
	n.n(u);
	L.PM = L.PM || {
		Map: o.a,
		Toolbar: a.a,
		Draw: s.a,
		Edit: l.a,
		version: i.version,
		initialize: function () {
			this.addInitHooks()
		},
		addInitHooks: function () {
			L.Map.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Map(this))
			}), L.LayerGroup.addInitHook(function () {
				this.pm = new L.PM.Edit.LayerGroup(this)
			}), L.Marker.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Edit.Marker(this))
			}), L.Polyline.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Edit.Line(this))
			}), L.Polygon.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Edit.Poly(this))
			}), L.Rectangle.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Edit.Rectangle(this))
			}), L.Circle.addInitHook(function () {
				this.options.pmIgnore || (this.pm = new L.PM.Edit.Circle(this))
			})
		}
	}, L.PM.initialize()
}, function (t, e) {
	Array.prototype.findIndex = Array.prototype.findIndex || function (t) {
		if (null === this) throw new TypeError("Array.prototype.findIndex called on null or undefined");
		if ("function" != typeof t) throw new TypeError("callback must be a function");
		for (var e = Object(this), n = e.length >>> 0, r = arguments[1], i = 0; i < n; i++)
			if (t.call(r, e[i], i, e)) return i;
		return -1
	}, Array.prototype.find = Array.prototype.find || function (t) {
		if (null === this) throw new TypeError("Array.prototype.find called on null or undefined");
		if ("function" != typeof t) throw new TypeError("callback must be a function");
		for (var e = Object(this), n = e.length >>> 0, r = arguments[1], i = 0; i < n; i++) {
			var o = e[i];
			if (t.call(r, o, i, e)) return o
		}
	}, "function" != typeof Object.assign && (Object.assign = function (t) {
		"use strict";
		if (null == t) throw new TypeError("Cannot convert undefined or null to object");
		t = Object(t);
		for (var e = 1; e < arguments.length; e++) {
			var n = arguments[e];
			if (null != n)
				for (var r in n) Object.prototype.hasOwnProperty.call(n, r) && (t[r] = n[r])
		}
		return t
	}), [Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach(function (t) {
		t.hasOwnProperty("remove") || Object.defineProperty(t, "remove", {
			configurable: !0,
			enumerable: !0,
			writable: !0,
			value: function () {
				this.parentNode.removeChild(this)
			}
		})
	})
}, function (t, e) {
	t.exports = {
		name: "leaflet.pm",
		version: "1.2.2",
		description: "A Leaflet Plugin For Editing Geometry Layers in Leaflet 1.0",
		keywords: ["leaflet", "polygon management", "geometry editing", "map data", "map overlay", "polygon", "geojson", "leaflet-draw", "data-field-geojson", "ui-leaflet-draw"],
		files: ["dist"],
		main: "dist/leaflet.pm.min.js",
		dependencies: {
			"@turf/difference": "^6.0.2",
			"@turf/intersect": "^6.1.3",
			"@turf/kinks": "6.x",
			lodash: "^4.17.11"
		},
		devDependencies: {
			"@babel/core": "^7.1.2",
			"@babel/preset-env": "^7.1.0",
			"babel-loader": "^8.0.4",
			"css-loader": "^0.28.11",
			cypress: "^3.1.0",
			eslint: "^4.18.2",
			"eslint-config-airbnb-base": "^12.1.0",
			"eslint-plugin-cypress": "^2.0.1",
			"eslint-plugin-import": "^2.9.0",
			"extract-text-webpack-plugin": "^3.0.2",
			"file-loader": "^0.11.1",
			leaflet: "^1.3.4",
			"style-loader": "^0.19.0",
			"uglifyjs-webpack-plugin": "^1.3.0",
			"url-loader": "^0.6.2",
			webpack: "^3.12.0"
		},
		peerDependencies: {
			leaflet: "^1.2.0"
		},
		scripts: {
			start: "npm run dev",
			dev: "./node_modules/.bin/webpack --config=webpack.dev.js",
			test: "$(npm bin)/cypress run",
			cypress: "$(npm bin)/cypress open",
			build: "./node_modules/.bin/webpack --config=webpack.build.js",
			prepare: "npm run build"
		},
		repository: {
			type: "git",
			url: "git+https://github.com/codeofsumit/leaflet.pm.git"
		},
		author: {
			name: "Sumit Kumar",
			email: "sk@outlook.com",
			url: "http://twitter.com/TweetsOfSumit"
		},
		license: "MIT",
		bugs: {
			url: "https://github.com/codeofsumit/leaflet.pm/issues"
		},
		homepage: "https://leafletpm.now.sh"
	}
}, function (t, e, n) {
	"use strict";
	var r = L.Class.extend({
		initialize: function (t) {
			var e = this;
			this.map = t, this.Draw = new L.PM.Draw(t), this.Toolbar = new L.PM.Toolbar(t), this.map.on("layerremove", function (t) {
				t.layer.pm && !t.layer._pmTempLayer && e.map.fire("pm:remove", t)
			}), this._globalRemovalMode = !1
		},
		addControls: function (t) {
			this.Toolbar.addControls(t)
		},
		removeControls: function () {
			this.Toolbar.removeControls()
		},
		toggleControls: function () {
			this.Toolbar.toggleControls()
		},
		controlsVisible: function () {
			return this.Toolbar.isVisible
		},
		enableDraw: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Poly",
				e = arguments.length > 1 ? arguments[1] : undefined;
			this.Draw.enable(t, e)
		},
		disableDraw: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Poly";
			this.Draw.disable(t)
		},
		setPathOptions: function (t) {
			this.Draw.setPathOptions(t)
		},
		removeLayer: function (t) {
			var e = t.target;
			e._layers || e.pm && e.pm.dragging() || t.target.remove()
		},
		toggleGlobalRemovalMode: function () {
			var t = this;
			this.globalRemovalEnabled() ? (this._globalRemovalMode = !1, this.map.eachLayer(function (e) {
				e.off("click", t.removeLayer)
			})) : (this._globalRemovalMode = !0, this.map.eachLayer(function (e) {
				!e.pm || e.pm.options && e.pm.options.preventMarkerRemoval || e.on("click", t.removeLayer)
			})), this.Toolbar.toggleButton("deleteLayer", this._globalRemovalMode)
		},
		globalRemovalEnabled: function () {
			return this._globalRemovalMode
		},
		globalEditEnabled: function () {
			return this._globalEditMode
		},
		enableGlobalEditMode: function (t) {
			var e = [];
			this.map.eachLayer(function (t) {
				(t instanceof L.Polyline || t instanceof L.Marker || t instanceof L.Circle) && e.push(t)
			}), e = (e = e.filter(function (t) {
				return !!t.pm
			})).filter(function (t) {
				return !t._pmTempLayer
			}), this._globalEditMode = !0, e.forEach(function (e) {
				e.pm.enable(t)
			}), this.Toolbar.toggleButton("editPolygon", this._globalEditMode), this._fireEditModeEvent(!0)
		},
		disableGlobalEditMode: function () {
			var t = [];
			this.map.eachLayer(function (e) {
				(e instanceof L.Polyline || e instanceof L.Marker || e instanceof L.Circle) && t.push(e)
			}), t = (t = t.filter(function (t) {
				return !!t.pm
			})).filter(function (t) {
				return !t._pmTempLayer
			}), this._globalEditMode = !1, t.forEach(function (t) {
				t.pm.disable()
			}), this.Toolbar.toggleButton("editPolygon", this._globalEditMode), this._fireEditModeEvent(!1)
		},
		_fireEditModeEvent: function (t) {
			this.map.fire("pm:globaleditmodetoggled", {
				enabled: t,
				map: this.map
			})
		},
		toggleGlobalEditMode: function (t) {
			this.globalEditEnabled() ? this.disableGlobalEditMode() : this.enableGlobalEditMode(t)
		}
	});
	e.a = r
}, function (t, e, n) {
	"use strict";
	var r = n(25);
	L.Control.PMButton = r.a;
	var i = L.Class.extend({
		options: {
			drawMarker: !0,
			drawPolygon: !0,
			drawPolyline: !0,
			drawCircle: !0,
			drawRectangle: !0,
			editMode: !0,
			cutPolygon: !0,
			dragPolygon: !1,
			removalMode: !0,
			position: "topleft"
		},
		initialize: function (t) {
			this.map = t, this.buttons = {}, this.isVisible = !1, this.container = L.DomUtil.create("div", "leaflet-pm-toolbar leaflet-bar leaflet-control"), this._defineButtons()
		},
		getButtons: function () {
			return this.buttons
		},
		addControls: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.options;
			"undefined" != typeof t.editPolygon && (t.editMode = t.editPolygon), "undefined" != typeof t.deleteLayer && (t.removalMode = t.deleteLayer), L.Util.setOptions(this, t), this.applyIconStyle(this.options.useFontAwesome), this._showHideButtons(), this.isVisible = !0
		},
		applyIconStyle: function (t) {
			var e = this.getButtons(),
				n = {
					fontawesome: {
						drawMarker: "control-fa-icon fas fa-map-marker-alt",
						drawPolyline: "control-fa-icon far fa-ellipsis-v",
						drawRectangle: "control-fa-icon far fa-draw-square",
						drawPolygon: "control-fa-icon far fa-draw-polygon",
						drawCircle: "control-fa-icon far fa-draw-circle",
						cutPolygon: "control-fa-icon far fa-cut",
						editMode: "control-fa-icon fas fa-pencil-alt",
						removalMode: "control-fa-icon far fa-trash-alt"
					},
					geomanIcons: {
						drawMarker: "control-icon leaflet-pm-icon-marker",
						drawPolyline: "control-icon leaflet-pm-icon-polyline",
						drawRectangle: "control-icon leaflet-pm-icon-rectangle",
						drawPolygon: "control-icon leaflet-pm-icon-polygon",
						drawCircle: "control-icon leaflet-pm-icon-circle",
						cutPolygon: "control-icon leaflet-pm-icon-cut",
						editMode: "control-icon leaflet-pm-icon-edit",
						removalMode: "control-icon leaflet-pm-icon-delete"
					}
				};
			for (var r in e) {
				var i = e[r];
				L.Util.setOptions(i, {
					className: t ? n.fontawesome[r] : n.geomanIcons[r]
				})
			}
		},
		removeControls: function () {
			var t = this.getButtons();
			for (var e in t) t[e].remove();
			this.isVisible = !1
		},
		toggleControls: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.options;
			this.isVisible ? this.removeControls() : this.addControls(t)
		},
		_addButton: function (t, e) {
			return this.buttons[t] = e, this.options[t] = this.options[t] || !1, this.buttons[t]
		},
		triggerClickOnToggledButtons: function (t) {
			for (var e in this.buttons) this.buttons[e] !== t && this.buttons[e].toggled() && this.buttons[e]._triggerClick()
		},
		toggleButton: function (t, e) {
			return "editPolygon" === t && (t = "editMode"), "deleteLayer" === t && (t = "removalMode"), this.triggerClickOnToggledButtons(this.buttons[t]), this.buttons[t].toggle(e)
		},
		_defineButtons: function () {
			var t = this,
				e = {
					className: "control-icon leaflet-pm-icon-marker",
					title: "Draw Marker",
					jsClass: "Marker",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Marker.toggle()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["cancel"]
				},
				n = {
					title: "Removal Mode",
					className: "control-icon leaflet-pm-icon-delete",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.toggleGlobalRemovalMode()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["cancel"]
				},
				r = {
					title: "Draw Polygon",
					className: "control-icon leaflet-pm-icon-polygon",
					jsClass: "Poly",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Poly.toggle()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["finish", "cancel"]
				},
				i = {
					title: "Cut Layers",
					className: "control-icon leaflet-pm-icon-cut",
					jsClass: "Cut",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Cut.toggle({
							snappable: !0,
							cursorMarker: !0,
							allowSelfIntersection: !1
						})
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["finish", "removeLastVertex", "cancel"]
				},
				o = {
					className: "control-icon leaflet-pm-icon-polyline",
					title: "Draw Polyline",
					jsClass: "Line",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Line.toggle()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["finish", "removeLastVertex", "cancel"]
				},
				a = {
					title: "Draw Circle",
					className: "control-icon leaflet-pm-icon-circle",
					jsClass: "Circle",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Circle.toggle()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["cancel"]
				},
				s = {
					title: "Draw Rectangle",
					className: "control-icon leaflet-pm-icon-rectangle",
					jsClass: "Rectangle",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.Draw.Rectangle.toggle()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["cancel"]
				},
				l = {
					title: "Edit Layers",
					className: "control-icon leaflet-pm-icon-edit",
					onClick: function () {},
					afterClick: function () {
						t.map.pm.toggleGlobalEditMode()
					},
					doToggle: !0,
					toggleStatus: !1,
					disableOtherButtons: !0,
					position: this.options.position,
					actions: ["cancel"]
				};
			this._addButton("drawMarker", new L.Control.PMButton(e)), this._addButton("drawPolyline", new L.Control.PMButton(o)), this._addButton("drawRectangle", new L.Control.PMButton(s)), this._addButton("drawPolygon", new L.Control.PMButton(r)), this._addButton("drawCircle", new L.Control.PMButton(a)), this._addButton("cutPolygon", new L.Control.PMButton(i)), this._addButton("editMode", new L.Control.PMButton(l)), this._addButton("removalMode", new L.Control.PMButton(n))
		},
		_showHideButtons: function () {
			this.removeControls();
			var t = this.getButtons();
			for (var e in t) this.options[e] && (t[e].setPosition(this.options.position), t[e].addTo(this.map))
		}
	});
	e.a = i
}, function (t, e, n) {
	"use strict";
	var r = L.Control.extend({
		options: {
			position: "topleft"
		},
		initialize: function (t) {
			this._button = L.Util.setOptions(this, t)
		},
		onAdd: function (t) {
			return this._map = t, this._container = this._map.pm.Toolbar.container, this.buttonsDomNode = this._makeButton(this._button), this._container.appendChild(this.buttonsDomNode), this._container
		},
		onRemove: function () {
			return this.buttonsDomNode.remove(), this._container
		},
		getText: function () {
			return this._button.text
		},
		getIconUrl: function () {
			return this._button.iconUrl
		},
		destroy: function () {
			this._button = {}, this._update()
		},
		toggle: function (t) {
			return this._button.toggleStatus = "boolean" == typeof t ? t : !this._button.toggleStatus, this._applyStyleClasses(), this._button.toggleStatus
		},
		toggled: function () {
			return this._button.toggleStatus
		},
		onCreate: function () {
			this.toggle(!1)
		},
		_triggerClick: function (t) {
			this._button.onClick(t), this._clicked(t), this._button.afterClick(t)
		},
		_makeButton: function (t) {
			var e = this,
				n = L.DomUtil.create("div", "button-container", this._container),
				r = L.DomUtil.create("a", "leaflet-buttons-control-button", n),
				i = L.DomUtil.create("div", "leaflet-pm-actions-container", n),
				o = {
					cancel: {
						text: "Cancel",
						onClick: function () {
							this._triggerClick()
						}
					},
					removeLastVertex: {
						text: "Remove Last Vertex",
						onClick: function () {
							this._map.pm.Draw[t.jsClass]._removeLastVertex()
						}
					},
					finish: {
						text: "Finish",
						onClick: function () {
							this._map.pm.Draw[t.jsClass]._finishShape()
						}
					}
				};
			t.actions.forEach(function (t) {
				var n = o[t],
					r = L.DomUtil.create("a", "leaflet-pm-action action-".concat(t), i);
				r.innerHTML = n.text, L.DomEvent.addListener(r, "click", n.onClick, e), L.DomEvent.disableClickPropagation(r)
			}), t.toggleStatus && L.DomUtil.addClass(r, "active");
			var a = L.DomUtil.create("div", "control-icon", r);
			return t.title && a.setAttribute("title", t.title), t.iconUrl && a.setAttribute("src", t.iconUrl), t.className && L.DomUtil.addClass(a, t.className), L.DomEvent.addListener(r, "click", function () {
				e._button.disableOtherButtons && e._map.pm.Toolbar.triggerClickOnToggledButtons(e)
			}), L.DomEvent.addListener(r, "click", this._triggerClick, this), L.DomEvent.disableClickPropagation(r), n
		},
		_applyStyleClasses: function () {
			this._container && (this._button.toggleStatus ? L.DomUtil.addClass(this.buttonsDomNode, "active") : L.DomUtil.removeClass(this.buttonsDomNode, "active"))
		},
		_clicked: function () {
			this._button.doToggle && this.toggle()
		}
	});
	e.a = r
}, function (t, e, n) {
	"use strict";
	var r = n(0);
	r.a.Marker = r.a.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Marker", this.toolbarButtonName = "drawMarker"
		},
		enable: function (t) {
			L.Util.setOptions(this, t), this._enabled = !0, this._map.on("click", this._createMarker, this), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !0), this._hintMarker = L.marker([0, 0], this.options.markerStyle), this._hintMarker._pmTempLayer = !0, this._hintMarker.addTo(this._map), this.options.tooltips && this._hintMarker.bindTooltip("Click to place marker", {
				permanent: !0,
				offset: L.point(0, 10),
				direction: "bottom",
				opacity: .8
			}).openTooltip(), this._layer = this._hintMarker, this._map.on("mousemove", this._syncHintMarker, this), this._map.fire("pm:drawstart", {
				shape: this._shape,
				workingLayer: this._layer
			}), this._map.eachLayer(function (t) {
				t instanceof L.Marker && t.pm && t.pm.enable()
			})
		},
		disable: function () {
			this._enabled && (this._map.off("click", this._createMarker, this), this._hintMarker.remove(), this._map.off("mousemove", this._syncHintMarker, this), this._map.eachLayer(function (t) {
				t instanceof L.Marker && t.pm && !t._pmTempLayer && t.pm.disable()
			}), this._map.fire("pm:drawend", {
				shape: this._shape
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !1), this._enabled = !1)
		},
		enabled: function () {
			return this._enabled
		},
		toggle: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		_createMarker: function (t) {
			if (t.latlng) {
				this._hintMarker._snapped || this._hintMarker.setLatLng(t.latlng);
				var e = this._hintMarker.getLatLng(),
					n = new L.Marker(e, this.options.markerStyle);
				n.addTo(this._map), n.pm.enable(), this._map.fire("pm:create", {
					shape: this._shape,
					marker: n,
					layer: n
				}), this._cleanupSnapping()
			}
		},
		_syncHintMarker: function (t) {
			if (this._hintMarker.setLatLng(t.latlng), this.options.snappable) {
				var e = t;
				e.target = this._hintMarker, this._handleSnapping(e)
			}
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(12),
		i = n.n(r),
		o = n(0);
	o.a.Line = o.a.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Line", this.toolbarButtonName = "drawPolyline", this._doesSelfIntersect = !1
		},
		enable: function (t) {
			L.Util.setOptions(this, t), this.options.finishOnDoubleClick && !this.options.finishOn && (this.options.finishOn = "dblclick"), this._enabled = !0, this._layerGroup = new L.LayerGroup, this._layerGroup._pmTempLayer = !0, this._layerGroup.addTo(this._map), this._layer = L.polyline([], this.options.templineStyle), this._layer._pmTempLayer = !0, this._layerGroup.addLayer(this._layer), this._hintline = L.polyline([], this.options.hintlineStyle), this._hintline._pmTempLayer = !0, this._layerGroup.addLayer(this._hintline), this._hintMarker = L.marker(this._map.getCenter(), {
				icon: L.divIcon({
					className: "marker-icon cursor-marker"
				})
			}), this._hintMarker._pmTempLayer = !0, this._layerGroup.addLayer(this._hintMarker), this.options.cursorMarker && L.DomUtil.addClass(this._hintMarker._icon, "visible"), this.options.tooltips && this._hintMarker.bindTooltip("Click to place first vertex", {
				permanent: !0,
				offset: L.point(0, 10),
				direction: "bottom",
				opacity: .8
			}).openTooltip(), this._map._container.style.cursor = "crosshair", this._map.on("click", this._createVertex, this), this.options.finishOn && this._map.on(this.options.finishOn, this._finishShape, this), "dblclick" === this.options.finishOn && (this.tempMapDoubleClickZoomState = this._map.doubleClickZoom._enabled, this.tempMapDoubleClickZoomState && this._map.doubleClickZoom.disable()), this._map.on("mousemove", this._syncHintMarker, this), this._hintMarker.on("move", this._syncHintLine, this), this._map.fire("pm:drawstart", {
				shape: this._shape,
				workingLayer: this._layer
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !0), this._otherSnapLayers = []
		},
		disable: function () {
			this._enabled && (this._enabled = !1, this._map._container.style.cursor = "", this._map.off("click", this._createVertex, this), this._map.off("mousemove", this._syncHintMarker, this), this.options.finishOn && this._map.off(this.options.finishOn, this._finishShape, this), this.tempMapDoubleClickZoomState && this._map.doubleClickZoom.enable(), this._map.removeLayer(this._layerGroup), this._map.fire("pm:drawend", {
				shape: this._shape
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !1), this.options.snappable && this._cleanupSnapping())
		},
		enabled: function () {
			return this._enabled
		},
		toggle: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		hasSelfIntersection: function () {
			return i()(this._layer.toGeoJSON(15)).features.length > 0
		},
		_syncHintLine: function () {
			var t = this._layer.getLatLngs();
			if (t.length > 0) {
				var e = t[t.length - 1];
				this._hintline.setLatLngs([e, this._hintMarker.getLatLng()])
			}
		},
		_syncHintMarker: function (t) {
			if (this._hintMarker.setLatLng(t.latlng), this.options.snappable) {
				var e = t;
				e.target = this._hintMarker, this._handleSnapping(e)
			}
			this.options.allowSelfIntersection || this._handleSelfIntersection()
		},
		_handleSelfIntersection: function () {
			var t = L.polyline(this._layer.getLatLngs());
			t.addLatLng(this._hintMarker.getLatLng());
			var e = i()(t.toGeoJSON(15));
			this._doesSelfIntersect = e.features.length > 0, this._doesSelfIntersect ? this._hintline.setStyle({
				color: "red"
			}) : this._hintline.setStyle(this.options.hintlineStyle)
		},
		_removeLastVertex: function () {
			var t = this._layer.getLatLngs(),
				e = t.pop();
			t.length < 1 && this.disable();
			var n = this._layerGroup.getLayers().filter(function (t) {
				return t instanceof L.Marker
			}).filter(function (t) {
				return !L.DomUtil.hasClass(t._icon, "cursor-marker")
			}).find(function (t) {
				return t.getLatLng() === e
			});
			this._layerGroup.removeLayer(n), this._layer.setLatLngs(t), this._syncHintLine()
		},
		_createVertex: function (t) {
			if (this.options.allowSelfIntersection || !this._doesSelfIntersect) {
				this._hintMarker._snapped || this._hintMarker.setLatLng(t.latlng);
				var e = this._hintMarker.getLatLng();
				if (e.equals(this._layer.getLatLngs()[0])) this._finishShape(t);
				else {
					var n = 0 === this._layer.getLatLngs().length;
					this._layer.addLatLng(e);
					var r = this._createMarker(e, n);
					this._hintline.setLatLngs([e, e]), this._layer.fire("pm:vertexadded", {
						shape: this._shape,
						workingLayer: this._layer,
						marker: r,
						latlng: e
					})
				}
			}
		},
		_finishShape: function () {
			if (this.options.allowSelfIntersection || !this._doesSelfIntersect) {
				var t = this._layer.getLatLngs();
				if (!(t.length <= 1)) {
					var e = L.polyline(t, this.options.pathOptions).addTo(this._map);
					this.disable(), this._map.fire("pm:create", {
						shape: this._shape,
						layer: e
					}), this.options.snappable && this._cleanupSnapping()
				}
			}
		},
		_createMarker: function (t, e) {
			var n = new L.Marker(t, {
				draggable: !1,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return n._pmTempLayer = !0, this._layerGroup.addLayer(n), n.on("click", this._finishShape, this), e && this._hintMarker.setTooltipContent("Click to continue drawing"), 2 === this._layer.getLatLngs().length && this._hintMarker.setTooltipContent("Click any existing marker to finish"), n
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(0);
	r.a.Poly = r.a.Line.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Poly", this.toolbarButtonName = "drawPolygon"
		},
		_finishShape: function (t) {
			if (this.options.allowSelfIntersection || !this._doesSelfIntersect) {
				var e = this._layer.getLatLngs();
				if (!(e.length <= 1)) {
					t && "dblclick" === t.type && e.splice(e.length - 1, 1);
					var n = L.polygon(e, this.options.pathOptions).addTo(this._map);
					this.disable(), this._map.fire("pm:create", {
						shape: this._shape,
						layer: n
					}), this._cleanupSnapping(), this._otherSnapLayers.splice(this._tempSnapLayerIndex, 1), delete this._tempSnapLayerIndex
				}
			}
		},
		_createMarker: function (t, e) {
			var n = new L.Marker(t, {
				draggable: !1,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return n._pmTempLayer = !0, this._layerGroup.addLayer(n), e && (n.on("click", this._finishShape, this), this._tempSnapLayerIndex = this._otherSnapLayers.push(n) - 1, this.options.snappable && this._cleanupSnapping()), e && this._hintMarker.setTooltipContent("Click to continue drawing"), 3 === this._layer.getLatLngs().length && this._hintMarker.setTooltipContent("Click first marker to finish"), n
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(0);
	r.a.Rectangle = r.a.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Rectangle", this.toolbarButtonName = "drawRectangle"
		},
		enable: function (t) {
			if (L.Util.setOptions(this, t), this._enabled = !0, this._layerGroup = new L.LayerGroup, this._layerGroup._pmTempLayer = !0, this._layerGroup.addTo(this._map), this._layer = L.rectangle([
					[0, 0],
					[0, 0]
				], this.options.pathOptions), this._layer._pmTempLayer = !0, this._startMarker = L.marker([0, 0], {
					icon: L.divIcon({
						className: "marker-icon rect-start-marker"
					}),
					draggable: !0,
					zIndexOffset: 100,
					opacity: this.options.cursorMarker ? 1 : 0
				}), this._startMarker._pmTempLayer = !0, this._layerGroup.addLayer(this._startMarker), this._hintMarker = L.marker([0, 0], {
					icon: L.divIcon({
						className: "marker-icon cursor-marker"
					})
				}), this._hintMarker._pmTempLayer = !0, this._layerGroup.addLayer(this._hintMarker), this.options.tooltips && this._hintMarker.bindTooltip("Click to place first vertex", {
					permanent: !0,
					offset: L.point(0, 10),
					direction: "bottom",
					opacity: .8
				}).openTooltip(), this.options.cursorMarker) {
				L.DomUtil.addClass(this._hintMarker._icon, "visible"), this._styleMarkers = [];
				for (var e = 0; e < 2; e += 1) {
					var n = L.marker([0, 0], {
						icon: L.divIcon({
							className: "marker-icon rect-style-marker"
						}),
						draggable: !0,
						zIndexOffset: 100
					});
					n._pmTempLayer = !0, this._layerGroup.addLayer(n), this._styleMarkers.push(n)
				}
			}
			this._map._container.style.cursor = "crosshair", this._map.on("click", this._placeStartingMarkers, this), this._map.on("mousemove", this._syncHintMarker, this), this._map.fire("pm:drawstart", {
				shape: this._shape,
				workingLayer: this._layer
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !0), this._otherSnapLayers = []
		},
		disable: function () {
			this._enabled && (this._enabled = !1, this._map._container.style.cursor = "", this._map.off("click", this._finishShape, this), this._map.off("click", this._placeStartingMarkers, this), this._map.off("mousemove", this._syncHintMarker, this), this._map.removeLayer(this._layerGroup), this._map.fire("pm:drawend", {
				shape: this._shape
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !1), this.options.snappable && this._cleanupSnapping())
		},
		enabled: function () {
			return this._enabled
		},
		toggle: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		_placeStartingMarkers: function (t) {
			this._hintMarker._snapped || this._hintMarker.setLatLng(t.latlng);
			var e = this._hintMarker.getLatLng();
			L.DomUtil.addClass(this._startMarker._icon, "visible"), this._startMarker.setLatLng(e), this.options.cursorMarker && this._styleMarkers && this._styleMarkers.forEach(function (t) {
				L.DomUtil.addClass(t._icon, "visible"), t.setLatLng(e)
			}), this._map.off("click", this._placeStartingMarkers, this), this._map.on("click", this._finishShape, this), this._hintMarker.setTooltipContent("Click to finish"), this._setRectangleOrigin()
		},
		_setRectangleOrigin: function () {
			var t = this._startMarker.getLatLng();
			t && (this._layerGroup.addLayer(this._layer), this._layer.setLatLngs([t, t]), this._hintMarker.on("move", this._syncRectangleSize, this))
		},
		_syncHintMarker: function (t) {
			if (this._hintMarker.setLatLng(t.latlng), this.options.snappable) {
				var e = t;
				e.target = this._hintMarker, this._handleSnapping(e)
			}
		},
		_syncRectangleSize: function () {
			var t = this,
				e = this._startMarker.getLatLng(),
				n = this._hintMarker.getLatLng();
			if (this._layer.setBounds([e, n]), this.options.cursorMarker && this._styleMarkers) {
				var r = [];
				this._findCorners().forEach(function (e) {
					e.equals(t._startMarker.getLatLng()) || e.equals(t._hintMarker.getLatLng()) || r.push(e)
				}), r.forEach(function (e, n) {
					t._styleMarkers[n].setLatLng(e)
				})
			}
		},
		_finishShape: function (t) {
			var e = this._startMarker.getLatLng(),
				n = t.latlng,
				r = L.rectangle([e, n], this.options.pathOptions).addTo(this._map);
			this.disable(), this._map.fire("pm:create", {
				shape: this._shape,
				layer: r
			})
		},
		_findCorners: function () {
			var t = this._layer.getBounds();
			return [t.getNorthWest(), t.getNorthEast(), t.getSouthEast(), t.getSouthWest()]
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(0);
	r.a.Circle = r.a.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Circle", this.toolbarButtonName = "drawCircle"
		},
		enable: function (t) {
			L.Util.setOptions(this, t), this.options.radius = 0, this._enabled = !0, this._layerGroup = new L.LayerGroup, this._layerGroup._pmTempLayer = !0, this._layerGroup.addTo(this._map), this._layer = L.circle([0, 0], this.options.templineStyle), this._layer._pmTempLayer = !0, this._layerGroup.addLayer(this._layer), this._centerMarker = L.marker([0, 0], {
				icon: L.divIcon({
					className: "marker-icon"
				}),
				draggable: !1,
				zIndexOffset: 100
			}), this._centerMarker._pmTempLayer = !0, this._layerGroup.addLayer(this._centerMarker), this._hintMarker = L.marker([0, 0], {
				icon: L.divIcon({
					className: "marker-icon cursor-marker"
				})
			}), this._hintMarker._pmTempLayer = !0, this._layerGroup.addLayer(this._hintMarker), this.options.cursorMarker && L.DomUtil.addClass(this._hintMarker._icon, "visible"), this.options.tooltips && this._hintMarker.bindTooltip("Click to place circle center", {
				permanent: !0,
				offset: L.point(0, 10),
				direction: "bottom",
				opacity: .8
			}).openTooltip(), this._hintline = L.polyline([], this.options.hintlineStyle), this._hintline._pmTempLayer = !0, this._layerGroup.addLayer(this._hintline), this._map._container.style.cursor = "crosshair", this._map.on("click", this._placeCenterMarker, this), this._map.on("mousemove", this._syncHintMarker, this), this._map.fire("pm:drawstart", {
				shape: this._shape,
				workingLayer: this._layer
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !0), this._otherSnapLayers = []
		},
		disable: function () {
			this._enabled && (this._enabled = !1, this._map._container.style.cursor = "", this._map.off("click", this._finishShape, this), this._map.off("click", this._placeCenterMarker, this), this._map.off("mousemove", this._syncHintMarker, this), this._map.removeLayer(this._layerGroup), this._map.fire("pm:drawend", {
				shape: this._shape
			}), this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, !1), this.options.snappable && this._cleanupSnapping())
		},
		enabled: function () {
			return this._enabled
		},
		toggle: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		_syncHintLine: function () {
			var t = this._centerMarker.getLatLng();
			this._hintline.setLatLngs([t, this._hintMarker.getLatLng()])
		},
		_syncCircleRadius: function () {
			var t = this._centerMarker.getLatLng(),
				e = this._hintMarker.getLatLng(),
				n = t.distanceTo(e);
			this._layer.setRadius(n)
		},
		_syncHintMarker: function (t) {
			if (this._hintMarker.setLatLng(t.latlng), this.options.snappable) {
				var e = t;
				e.target = this._hintMarker, this._handleSnapping(e)
			}
		},
		_placeCenterMarker: function (t) {
			this._hintMarker._snapped || this._hintMarker.setLatLng(t.latlng);
			var e = this._hintMarker.getLatLng();
			this._centerMarker.setLatLng(e), this._map.off("click", this._placeCenterMarker, this), this._map.on("click", this._finishShape, this), this._placeCircleCenter()
		},
		_placeCircleCenter: function () {
			var t = this._centerMarker.getLatLng();
			t && (this._layer.setLatLng(t), this._hintMarker.on("move", this._syncHintLine, this), this._hintMarker.on("move", this._syncCircleRadius, this), this._hintMarker.setTooltipContent("Click to finish circle"), this._layer.fire("pm:centerplaced", {
				shape: this._shape,
				workingLayer: this._layer,
				latlng: t
			}))
		},
		_finishShape: function (t) {
			var e = this._centerMarker.getLatLng(),
				n = t.latlng,
				r = e.distanceTo(n),
				i = Object.assign({}, this.options.pathOptions, {
					radius: r
				}),
				o = L.circle(e, i).addTo(this._map);
			this.disable(), this._map.fire("pm:create", {
				shape: this._shape,
				layer: o
			})
		},
		_createMarker: function (t) {
			var e = new L.Marker(t, {
				draggable: !1,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return e._pmTempLayer = !0, this._layerGroup.addLayer(e), e
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(32),
		i = n.n(r),
		o = n(33),
		a = n(0);
	a.a.Cut = a.a.Poly.extend({
		initialize: function (t) {
			this._map = t, this._shape = "Cut", this.toolbarButtonName = "cutPolygon"
		},
		_cut: function (t) {
			var e = this,
				n = this._map._layers;
			Object.keys(n).map(function (t) {
				return n[t]
			}).filter(function (t) {
				return t.pm
			}).filter(function (t) {
				return t instanceof L.Polygon
			}).filter(function (e) {
				return e !== t
			}).filter(function (e) {
				try {
					return !!i()(t.toGeoJSON(15), e.toGeoJSON(15))
				} catch (n) {
					return console.error("You cant cut polygons with self-intersections"), !1
				}
			}).forEach(function (n) {
				var r = Object(o.a)(n.toGeoJSON(15), t.toGeoJSON(15)),
					i = L.geoJSON(r, n.options).addTo(e._map);
				i.addTo(e._map), i.pm.enable(e.options), i.pm.disable(), n.fire("pm:cut", {
					shape: e._shape,
					layer: i,
					originalLayer: n
				}), e._map.fire("pm:cut", {
					shape: e._shape,
					layer: i,
					originalLayer: n
				}), n._pmTempLayer = !0, t._pmTempLayer = !0, n.remove(), t.remove()
			})
		},
		_finishShape: function () {
			var t = this._layer.getLatLngs(),
				e = L.polygon(t, this.options.pathOptions);
			this._cut(e), this.disable(), this._cleanupSnapping(), this._otherSnapLayers.splice(this._tempSnapLayerIndex, 1), delete this._tempSnapLayerIndex
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = this && this.__importStar || function (t) {
		if (t && t.__esModule) return t;
		var e = {};
		if (null != t)
			for (var n in t) Object.hasOwnProperty.call(t, n) && (e[n] = t[n]);
		return e["default"] = t, e
	};
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var i = n(2),
		o = n(13),
		a = r(n(14));
	e["default"] = function s(t, e, n) {
		void 0 === n && (n = {});
		var r = o.getGeom(t),
			l = o.getGeom(e);
		if ("Polygon" === r.type && "Polygon" === l.type) {
			var h = a.intersection(r.coordinates, l.coordinates);
			if (null === h || 0 === h.length) return null;
			if (1 === h.length) {
				var u = h[0][0][0],
					c = h[0][0][h[0][0].length - 1];
				return u[0] === c[0] && u[1] === c[1] ? i.polygon(h[0], n.properties) : null
			}
			return i.multiPolygon(h, n.properties)
		}
		if ("MultiPolygon" === r.type) {
			for (var p = [], f = 0, d = r.coordinates; f < d.length; f++) {
				var g = d[f],
					_ = s(o.getGeom(i.polygon(g)), l);
				if (_) {
					var m = o.getGeom(_);
					if ("Polygon" === m.type) p.push(m.coordinates);
					else {
						if ("MultiPolygon" !== m.type) throw new Error("intersection is invalid");
						p = p.concat(m.coordinates)
					}
				}
			}
			return 0 === p.length ? null : 1 === p.length ? i.polygon(p[0], n.properties) : i.multiPolygon(p, n.properties)
		}
		if ("MultiPolygon" === l.type) return s(l, r);
		throw new Error("poly1 and poly2 must be either polygons or multiPolygons")
	}
}, function (t, e, n) {
	"use strict";
	var r = n(14),
		i = (n.n(r), n(34)),
		o = n.n(i),
		a = n(2),
		s = (n.n(a), n(13)),
		l = (n.n(s), n(15));
	n.n(l);

	function h(t) {
		switch (t.type) {
			case "Polygon":
				return o()(t) > 1 ? t : null;
			case "MultiPolygon":
				var e = [];
				if (Object(l.flattenEach)(t, function (t) {
						o()(t) > 1 && e.push(t.geometry.coordinates)
					}), e.length) return {
					type: "MultiPolygon",
					coordinates: e
				}
		}
	}
	e.a = function (t, e) {
		var n = Object(s.getGeom)(t),
			i = Object(s.getGeom)(e),
			o = t.properties || {};
		if (n = h(n), i = h(i), !n) return null;
		if (!i) return Object(a.feature)(n, o);
		var l = r.diff(n.coordinates, i.coordinates);
		return 0 === l.length ? null : 1 === l.length ? Object(a.polygon)(l[0], o) : Object(a.multiPolygon)(l, o)
	}
}, function (t, e, n) {
	"use strict";
	Object.defineProperty(e, "__esModule", {
		value: !0
	});
	var r = n(15),
		i = 6378137;

	function o(t) {
		var e = 0;
		if (t && t.length > 0) {
			e += Math.abs(a(t[0]));
			for (var n = 1; n < t.length; n++) e -= Math.abs(a(t[n]))
		}
		return e
	}

	function a(t) {
		var e, n, r, o, a, l, h = 0,
			u = t.length;
		if (u > 2) {
			for (l = 0; l < u; l++) l === u - 2 ? (r = u - 2, o = u - 1, a = 0) : l === u - 1 ? (r = u - 1, o = 0, a = 1) : (r = l, o = l + 1, a = l + 2), e = t[r], n = t[o], h += (s(t[a][0]) - s(e[0])) * Math.sin(s(n[1]));
			h = h * i * i / 2
		}
		return h
	}

	function s(t) {
		return t * Math.PI / 180
	}
	e["default"] = function (t) {
		return r.geomReduce(t, function (t, e) {
			return t + function (t) {
				var e, n = 0;
				switch (t.type) {
					case "Polygon":
						return o(t.coordinates);
					case "MultiPolygon":
						for (e = 0; e < t.coordinates.length; e++) n += o(t.coordinates[e]);
						return n;
					case "Point":
					case "MultiPoint":
					case "LineString":
					case "MultiLineString":
						return 0
				}
				return 0
			}(e)
		}, 0)
	}
}, function (t, e, n) {
	"use strict";
	var r = {
		_initDraggableLayer: function () {
//			this._tempDragCoord = null;
//			var t = this._layer._path ? this._layer._path : this._layer._renderer._container;
//			L.DomUtil.addClass(t, "leaflet-pm-draggable"), this._originalMapDragState = this._layer._map.dragging._enabled, this._safeToCacheDragState = !0, this._layer.on("mousedown", this._dragMixinOnMouseDown, this)
		},
		_dragMixinOnMouseUp: function () {
			var t = this,
				e = this._layer._path ? this._layer._path : this._layer._renderer._container;
			return this._originalMapDragState && this._layer._map.dragging.enable(), this._safeToCacheDragState = !0, this._layer._map.off("mousemove", this._dragMixinOnMouseMove, this), this._layer.off("mouseup", this._dragMixinOnMouseUp, this), !!this._dragging && (this._initMarkers(), window.setTimeout(function () {
				t._dragging = !1, L.DomUtil.removeClass(e, "leaflet-pm-dragging"), t._layer.fire("pm:dragend"), t._fireEdit()
			}, 10), !0)
		},
		_dragMixinOnMouseMove: function (t) {
			var e = this._layer._path ? this._layer._path : this._layer._renderer._container;
			this._dragging || (this._dragging = !0, L.DomUtil.addClass(e, "leaflet-pm-dragging"), this._layer.bringToFront(), this._originalMapDragState && this._layer._map.dragging.disable(), this._markerGroup.clearLayers(), this._layer.fire("pm:dragstart")), this._onLayerDrag(t)
		},
		_dragMixinOnMouseDown: function (t) {
			t.originalEvent.button > 0 || (this._safeToCacheDragState && (this._originalMapDragState = this._layer._map.dragging._enabled, this._safeToCacheDragState = !1), this._tempDragCoord = t.latlng, this._layer.on("mouseup", this._dragMixinOnMouseUp, this), this._layer._map.on("mousemove", this._dragMixinOnMouseMove, this))
		},
		dragging: function () {
			return this._dragging
		},
		_onLayerDrag: function (t) {
			var e = t.latlng,
				n = e.lat - this._tempDragCoord.lat,
				r = e.lng - this._tempDragCoord.lng,
				i = function o(t) {
					return t.map(function (t) {
						return Array.isArray(t) ? o(t) : {
							lat: t.lat + n,
							lng: t.lng + r
						}
					})
				}(this._layer._latlngs);
			this._layer.setLatLngs(i).redraw(), this._tempDragCoord = e, this._layer.fire("pm:drag")
		}
	};
	e.a = r
}, function (t, e, n) {
	"use strict";
	n(1).a.LayerGroup = L.Class.extend({
		initialize: function (t) {
			var e = this;
			this._layerGroup = t, this._layers = this.findLayers(), this._layers.forEach(function (t) {
				return e._initLayer(t)
			}), this._layerGroup.on("layeradd", function (t) {
				t.target._pmTempLayer || (e._layers = e.findLayers(), t.layer.pm && e._initLayer(t.layer), t.target.pm.enabled() && e.enable(e.getOptions()))
			})
		},
		findLayers: function () {
			var t = this._layerGroup.getLayers();
			return t = (t = (t = t.filter(function (t) {
				return !(t instanceof L.LayerGroup)
			})).filter(function (t) {
				return !!t.pm
			})).filter(function (t) {
				return !t._pmTempLayer
			})
		},
		_initLayer: function (t) {
			var e = this;
			["pm:edit", "pm:update", "pm:remove", "pm:dragstart", "pm:drag", "pm:dragend", "pm:snap", "pm:unsnap", "pm:cut", "pm:intersect", "pm:raiseMarkers", "pm:markerdragend", "pm:markerdragstart", "pm:vertexadded", "pm:vertexremoved", "pm:centerplaced"].forEach(function (n) {
				t.on(n, e._fireEvent, e)
			}), t.pm._layerGroup = this._layerGroup
		},
		_fireEvent: function (t) {
			this._layerGroup.fireEvent(t.type, t)
		},
		toggleEdit: function (t) {
			this._options = t, this._layers.forEach(function (e) {
				e.pm.toggleEdit(t)
			})
		},
		enable: function (t) {
			this._options = t, this._layers.forEach(function (e) {
				e.pm.enable(t)
			})
		},
		disable: function () {
			this._layers.forEach(function (t) {
				t.pm.disable()
			})
		},
		enabled: function () {
			var t = this._layers.find(function (t) {
				return t.pm.enabled()
			});
			return !!t
		},
		dragging: function () {
			var t = this._layers.find(function (t) {
				return t.pm.dragging()
			});
			return !!t
		},
		getOptions: function () {
			return this._options
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(1);
	r.a.Marker = r.a.extend({
		initialize: function (t) {
			this._layer = t, this._enabled = !1, this._layer.on("dragend", this._onDragEnd, this)
		},
		toggleEdit: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		enable: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
				draggable: !0,
				snappable: !0
			};
			L.Util.setOptions(this, t), this._map = this._layer._map, this.enabled() || (this._enabled = !0, this.options.preventMarkerRemoval || this._layer.on("contextmenu", this._removeMarker, this), this.options.draggable && this._layer.dragging.enable(), this.options.snappable && this._initSnappableMarkers())
		},
		enabled: function () {
			return this._enabled
		},
		disable: function () {
			this._enabled = !1, this._layer.dragging && this._layer.dragging.disable(), this._layer.off("contextmenu", this._removeMarker, this), this._layerEdited && this._layer.fire("pm:update", {}), this._layerEdited = !1
		},
		_removeMarker: function (t) {
			var e = t.target;
			e.remove(), e.fire("pm:remove")
		},
		_onDragEnd: function (t) {
			t.target.fire("pm:edit"), this._layerEdited = !0
		},
		_initSnappableMarkers: function () {
			var t = this._layer;
			this.options.snapDistance = this.options.snapDistance || 30, t.off("drag", this._handleSnapping, this), t.on("drag", this._handleSnapping, this), t.off("dragend", this._cleanupSnapping, this), t.on("dragend", this._cleanupSnapping, this), t.off("pm:dragstart", this._unsnap, this), t.on("pm:dragstart", this._unsnap, this)
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(12),
		i = n.n(r),
		o = n(39),
		a = n.n(o),
		s = n(1),
		l = n(11);
	s.a.Line = s.a.extend({
		initialize: function (t) {
			this._layer = t, this._enabled = !1
		},
		toggleEdit: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		enable: function (t) {
			L.Util.setOptions(this, t), this._map = this._layer._map, this._map && (this.enabled() || this.disable(), this._enabled = !0, this._initMarkers(), this._layer.on("remove", this._onLayerRemove, this), this.options.allowSelfIntersection || this._layer.on("pm:vertexremoved", this._handleSelfIntersectionOnVertexRemoval, this), this.options.draggable && this._initDraggableLayer(), this.options.allowSelfIntersection || this._handleLayerStyle())
		},
		_onLayerRemove: function (t) {
			this.disable(t.target)
		},
		enabled: function () {
			return this._enabled
		},
		disable: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this._layer;
			if (!this.enabled()) return !1;
			if (t.pm._dragging) return !1;
			t.pm._enabled = !1, t.pm._markerGroup.clearLayers(), t.off("mousedown"), t.off("mouseup"), this._layer.off("remove", this._onLayerRemove), this.options.allowSelfIntersection || this._layer.off("pm:vertexremoved", this._handleSelfIntersectionOnVertexRemoval);
			var e = t._path ? t._path : this._layer._renderer._container;
			return L.DomUtil.removeClass(e, "leaflet-pm-draggable"), this.hasSelfIntersection() && L.DomUtil.removeClass(e, "leaflet-pm-invalid"), this._layerEdited && this._layer.fire("pm:update", {}), this._layerEdited = !1, !0
		},
		hasSelfIntersection: function () {
			return i()(this._layer.toGeoJSON(15)).features.length > 0
		},
		_handleSelfIntersectionOnVertexRemoval: function () {
			this._handleLayerStyle(!0), this.hasSelfIntersection() && (this._layer.setLatLngs(this._coordsBeforeEdit), this._coordsBeforeEdit = null, this._initMarkers())
		},
		_handleLayerStyle: function (t) {
			var e = this._layer._path ? this._layer._path : this._layer._renderer._container;
			if (this.hasSelfIntersection()) {
				if (L.DomUtil.hasClass(e, "leaflet-pm-invalid")) return;
				t ? (L.DomUtil.addClass(e, "leaflet-pm-invalid"), window.setTimeout(function () {
					L.DomUtil.removeClass(e, "leaflet-pm-invalid")
				}, 200)) : L.DomUtil.addClass(e, "leaflet-pm-invalid"), this._layer.fire("pm:intersect", {
					intersection: i()(this._layer.toGeoJSON(15))
				})
			} else L.DomUtil.removeClass(e, "leaflet-pm-invalid")
		},
		_initMarkers: function () {
			var t = this,
				e = this._map,
				n = this._layer.getLatLngs();
			this._markerGroup && this._markerGroup.clearLayers(), this._markerGroup = new L.LayerGroup, this._markerGroup._pmTempLayer = !0, e.addLayer(this._markerGroup);
			this._markers = function r(e) {
				if (Array.isArray(e[0])) return e.map(r, t);
				var n = e.map(t._createMarker, t);
				return e.map(function (r, i) {
					var o = t.isPolygon() ? (i + 1) % e.length : i + 1;
					return t._createMiddleMarker(n[i], n[o])
				}), n
			}(n), this.options.snappable && this._initSnappableMarkers()
		},
		_createMarker: function (t) {
			var e = new L.Marker(t, {
				draggable: !this.options.preventVertexEdit,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return e._pmTempLayer = !0, e.on("dragstart", this._onMarkerDragStart, this), e.on("move", this._onMarkerDrag, this), e.on("dragend", this._onMarkerDragEnd, this), this.options.preventMarkerRemoval || e.on("contextmenu", this._removeMarker, this), this._markerGroup.addLayer(e), e
		},
		_createMiddleMarker: function (t, e) {
			var n = this;
			if (!t || !e) return !1;
			var r = l.a.calcMiddleLatLng(this._map, t.getLatLng(), e.getLatLng()),
				i = this._createMarker(r),
				o = L.divIcon({
					className: "marker-icon marker-icon-middle"
				});
			return i.setIcon(o), t._middleMarkerNext = i, e._middleMarkerPrev = i, i.on("click", function () {
				var r = L.divIcon({
					className: "marker-icon"
				});
				i.setIcon(r), n._addMarker(i, t, e)
			}), i.on("movestart", function () {
				i.on("moveend", function () {
					var t = L.divIcon({
						className: "marker-icon"
					});
					i.setIcon(t), i.off("moveend")
				}), n._addMarker(i, t, e)
			}), i
		},
		_addMarker: function (t, e, n) {
			t.off("movestart"), t.off("click");
			var r = t.getLatLng(),
				i = this._layer._latlngs,
				o = this.findDeepMarkerIndex(this._markers, e),
				s = o.indexPath,
				l = o.index,
				h = o.parentPath,
				u = s.length > 1 ? a()(i, h) : i,
				c = s.length > 1 ? a()(this._markers, h) : this._markers;
			u.splice(l + 1, 0, r), c.splice(l + 1, 0, t), this._layer.setLatLngs(i), this._createMiddleMarker(e, t), this._createMiddleMarker(t, n), this._fireEdit(), this._layer.fire("pm:vertexadded", {
				layer: this._layer,
				marker: t,
				indexPath: this.findDeepMarkerIndex(this._markers, t).indexPath,
				latlng: r
			}), this.options.snappable && this._initSnappableMarkers()
		},
		_removeMarker: function (t) {
			if (!this.options.allowSelfIntersection) {
				var e = this._layer.getLatLngs();
				this._coordsBeforeEdit = JSON.parse(JSON.stringify(e))
			}
			var n = t.target,
				r = this._layer.getLatLngs(),
				i = this.findDeepMarkerIndex(this._markers, n),
				o = i.indexPath,
				s = i.index,
				l = i.parentPath;
			if (o) {
				var h, u, c = o.length > 1 ? a()(r, l) : r,
					p = o.length > 1 ? a()(this._markers, l) : this._markers;
				if (c.splice(s, 1), this._layer.setLatLngs(r), c.length <= 1 && (c.splice(0, c.length), this._layer.setLatLngs(r), this.disable(), this.enable(this.options)), this.isEmptyDeep(r) && this._layer.remove(), n._middleMarkerPrev && this._markerGroup.removeLayer(n._middleMarkerPrev), n._middleMarkerNext && this._markerGroup.removeLayer(n._middleMarkerNext), this._markerGroup.removeLayer(n), this.isPolygon() ? (h = (s + 1) % p.length, u = (s + (p.length - 1)) % p.length) : (u = s - 1 < 0 ? undefined : s - 1, h = s + 1 >= p.length ? undefined : s + 1), h !== u) {
					var f = p[u],
						d = p[h];
					this._createMiddleMarker(f, d)
				}
				p.splice(s, 1), this._fireEdit(), this._layer.fire("pm:vertexremoved", {
					layer: this._layer,
					marker: n,
					indexPath: o
				})
			}
		},
		isEmptyDeep: function (t) {
			return ! function e(t) {
				return t.filter(function (t) {
					return ![null, "", undefined].includes(t)
				}).reduce(function (t, n) {
					return t.concat(Array.isArray(n) ? e(n) : n)
				}, [])
			}(t).length
		},
		findDeepMarkerIndex: function (t, e) {
			var n;
			t.some(function i(t) {
				return function (r, o) {
					var a = t.concat(o);
					return r._leaflet_id === e._leaflet_id ? (n = a, !0) : Array.isArray(r) && r.some(i(a))
				}
			}([]));
			var r = {};
			return n && (r = {
				indexPath: n,
				index: n[n.length - 1],
				parentPath: n.slice(0, n.length - 1)
			}), r
		},
		updatePolygonCoordsFromMarkerDrag: function (t) {
			var e = this._layer.getLatLngs(),
				n = t.getLatLng(),
				r = this.findDeepMarkerIndex(this._markers, t),
				i = r.indexPath,
				o = r.index,
				s = r.parentPath;
			(i.length > 1 ? a()(e, s) : e).splice(o, 1, n), this._layer.setLatLngs(e).redraw()
		},
		_onMarkerDrag: function (t) {
			var e = t.target,
				n = this.findDeepMarkerIndex(this._markers, e),
				r = n.indexPath,
				i = n.index,
				o = n.parentPath;
			if (r) {
				this.updatePolygonCoordsFromMarkerDrag(e);
				var s = r.length > 1 ? a()(this._markers, o) : this._markers,
					h = (i + 1) % s.length,
					u = (i + (s.length - 1)) % s.length,
					c = e.getLatLng(),
					p = s[u].getLatLng(),
					f = s[h].getLatLng();
				if (e._middleMarkerNext) {
					var d = l.a.calcMiddleLatLng(this._map, c, f);
					e._middleMarkerNext.setLatLng(d)
				}
				if (e._middleMarkerPrev) {
					var g = l.a.calcMiddleLatLng(this._map, c, p);
					e._middleMarkerPrev.setLatLng(g)
				}
				this.options.allowSelfIntersection || this._handleLayerStyle()
			}
		},
		_onMarkerDragEnd: function (t) {
			var e = t.target,
				n = this.findDeepMarkerIndex(this._markers, e).indexPath;
			if (!this.options.allowSelfIntersection && this.hasSelfIntersection()) return this._layer.setLatLngs(this._coordsBeforeEdit), this._coordsBeforeEdit = null, this._initMarkers(), void this._handleLayerStyle();
			this._layer.fire("pm:markerdragend", {
				markerEvent: t,
				indexPath: n
			}), this._fireEdit()
		},
		_onMarkerDragStart: function (t) {
			var e = t.target,
				n = this.findDeepMarkerIndex(this._markers, e).indexPath;
			this._layer.fire("pm:markerdragstart", {
				markerEvent: t,
				indexPath: n
			}), this.options.allowSelfIntersection || (this._coordsBeforeEdit = this._layer.getLatLngs())
		},
		_fireEdit: function () {
			this._layerEdited = !0, this._layer.fire("pm:edit")
		}
	})
}, function (t, e, n) {
	var r = n(40);
	t.exports = function (t, e, n) {
		var i = null == t ? undefined : r(t, e);
		return i === undefined ? n : i
	}
}, function (t, e, n) {
	var r = n(41),
		i = n(81);
	t.exports = function (t, e) {
		for (var n = 0, o = (e = r(e, t)).length; null != t && n < o;) t = t[i(e[n++])];
		return n && n == o ? t : undefined
	}
}, function (t, e, n) {
	var r = n(6),
		i = n(42),
		o = n(48),
		a = n(78);
	t.exports = function (t, e) {
		return r(t) ? t : i(t, e) ? [t] : o(a(t))
	}
}, function (t, e, n) {
	var r = n(6),
		i = n(7),
		o = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/,
		a = /^\w*$/;
	t.exports = function (t, e) {
		if (r(t)) return !1;
		var n = typeof t;
		return !("number" != n && "symbol" != n && "boolean" != n && null != t && !i(t)) || a.test(t) || !o.test(t) || null != e && t in Object(e)
	}
}, function (t, e, n) {
	(function (e) {
		var n = "object" == typeof e && e && e.Object === Object && e;
		t.exports = n
	}).call(e, n(44))
}, function (t, e) {
	var n;
	n = function () {
		return this
	}();
	try {
		n = n || Function("return this")() || (0, eval)("this")
	} catch (r) {
		"object" == typeof window && (n = window)
	}
	t.exports = n
}, function (t, e, n) {
	var r = n(8),
		i = Object.prototype,
		o = i.hasOwnProperty,
		a = i.toString,
		s = r ? r.toStringTag : undefined;
	t.exports = function (t) {
		var e = o.call(t, s),
			n = t[s];
		try {
			t[s] = undefined;
			var r = !0
		} catch (l) {}
		var i = a.call(t);
		return r && (e ? t[s] = n : delete t[s]), i
	}
}, function (t, e) {
	var n = Object.prototype.toString;
	t.exports = function (t) {
		return n.call(t)
	}
}, function (t, e) {
	t.exports = function (t) {
		return null != t && "object" == typeof t
	}
}, function (t, e, n) {
	var r = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,
		i = /\\(\\)?/g,
		o = n(49)(function (t) {
			var e = [];
			return 46 === t.charCodeAt(0) && e.push(""), t.replace(r, function (t, n, r, o) {
				e.push(r ? o.replace(i, "$1") : n || t)
			}), e
		});
	t.exports = o
}, function (t, e, n) {
	var r = n(50),
		i = 500;
	t.exports = function (t) {
		var e = r(t, function (t) {
				return n.size === i && n.clear(), t
			}),
			n = e.cache;
		return e
	}
}, function (t, e, n) {
	var r = n(51),
		i = "Expected a function";

	function o(t, e) {
		if ("function" != typeof t || null != e && "function" != typeof e) throw new TypeError(i);
		var n = function () {
			var r = arguments,
				i = e ? e.apply(this, r) : r[0],
				o = n.cache;
			if (o.has(i)) return o.get(i);
			var a = t.apply(this, r);
			return n.cache = o.set(i, a) || o, a
		};
		return n.cache = new(o.Cache || r), n
	}
	o.Cache = r, t.exports = o
}, function (t, e, n) {
	var r = n(52),
		i = n(73),
		o = n(75),
		a = n(76),
		s = n(77);

	function l(t) {
		var e = -1,
			n = null == t ? 0 : t.length;
		for (this.clear(); ++e < n;) {
			var r = t[e];
			this.set(r[0], r[1])
		}
	}
	l.prototype.clear = r, l.prototype["delete"] = i, l.prototype.get = o, l.prototype.has = a, l.prototype.set = s, t.exports = l
}, function (t, e, n) {
	var r = n(53),
		i = n(65),
		o = n(72);
	t.exports = function () {
		this.size = 0, this.__data__ = {
			hash: new r,
			map: new(o || i),
			string: new r
		}
	}
}, function (t, e, n) {
	var r = n(54),
		i = n(61),
		o = n(62),
		a = n(63),
		s = n(64);

	function l(t) {
		var e = -1,
			n = null == t ? 0 : t.length;
		for (this.clear(); ++e < n;) {
			var r = t[e];
			this.set(r[0], r[1])
		}
	}
	l.prototype.clear = r, l.prototype["delete"] = i, l.prototype.get = o, l.prototype.has = a, l.prototype.set = s, t.exports = l
}, function (t, e, n) {
	var r = n(3);
	t.exports = function () {
		this.__data__ = r ? r(null) : {}, this.size = 0
	}
}, function (t, e, n) {
	var r = n(56),
		i = n(57),
		o = n(18),
		a = n(59),
		s = /^\[object .+?Constructor\]$/,
		l = Function.prototype,
		h = Object.prototype,
		u = l.toString,
		c = h.hasOwnProperty,
		p = RegExp("^" + u.call(c).replace(/[\\^$.*+?()[\]{}|]/g, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
	t.exports = function (t) {
		return !(!o(t) || i(t)) && (r(t) ? p : s).test(a(t))
	}
}, function (t, e, n) {
	var r = n(16),
		i = n(18),
		o = "[object AsyncFunction]",
		a = "[object Function]",
		s = "[object GeneratorFunction]",
		l = "[object Proxy]";
	t.exports = function (t) {
		if (!i(t)) return !1;
		var e = r(t);
		return e == a || e == s || e == o || e == l
	}
}, function (t, e, n) {
	var r = n(58),
		i = function () {
			var t = /[^.]+$/.exec(r && r.keys && r.keys.IE_PROTO || "");
			return t ? "Symbol(src)_1." + t : ""
		}();
	t.exports = function (t) {
		return !!i && i in t
	}
}, function (t, e, n) {
	var r = n(9)["__core-js_shared__"];
	t.exports = r
}, function (t, e) {
	var n = Function.prototype.toString;
	t.exports = function (t) {
		if (null != t) {
			try {
				return n.call(t)
			} catch (e) {}
			try {
				return t + ""
			} catch (e) {}
		}
		return ""
	}
}, function (t, e) {
	t.exports = function (t, e) {
		return null == t ? undefined : t[e]
	}
}, function (t, e) {
	t.exports = function (t) {
		var e = this.has(t) && delete this.__data__[t];
		return this.size -= e ? 1 : 0, e
	}
}, function (t, e, n) {
	var r = n(3),
		i = "__lodash_hash_undefined__",
		o = Object.prototype.hasOwnProperty;
	t.exports = function (t) {
		var e = this.__data__;
		if (r) {
			var n = e[t];
			return n === i ? undefined : n
		}
		return o.call(e, t) ? e[t] : undefined
	}
}, function (t, e, n) {
	var r = n(3),
		i = Object.prototype.hasOwnProperty;
	t.exports = function (t) {
		var e = this.__data__;
		return r ? e[t] !== undefined : i.call(e, t)
	}
}, function (t, e, n) {
	var r = n(3),
		i = "__lodash_hash_undefined__";
	t.exports = function (t, e) {
		var n = this.__data__;
		return this.size += this.has(t) ? 0 : 1, n[t] = r && e === undefined ? i : e, this
	}
}, function (t, e, n) {
	var r = n(66),
		i = n(67),
		o = n(69),
		a = n(70),
		s = n(71);

	function l(t) {
		var e = -1,
			n = null == t ? 0 : t.length;
		for (this.clear(); ++e < n;) {
			var r = t[e];
			this.set(r[0], r[1])
		}
	}
	l.prototype.clear = r, l.prototype["delete"] = i, l.prototype.get = o, l.prototype.has = a, l.prototype.set = s, t.exports = l
}, function (t, e) {
	t.exports = function () {
		this.__data__ = [], this.size = 0
	}
}, function (t, e, n) {
	var r = n(4),
		i = Array.prototype.splice;
	t.exports = function (t) {
		var e = this.__data__,
			n = r(e, t);
		return !(n < 0 || (n == e.length - 1 ? e.pop() : i.call(e, n, 1), --this.size, 0))
	}
}, function (t, e) {
	t.exports = function (t, e) {
		return t === e || t != t && e != e
	}
}, function (t, e, n) {
	var r = n(4);
	t.exports = function (t) {
		var e = this.__data__,
			n = r(e, t);
		return n < 0 ? undefined : e[n][1]
	}
}, function (t, e, n) {
	var r = n(4);
	t.exports = function (t) {
		return r(this.__data__, t) > -1
	}
}, function (t, e, n) {
	var r = n(4);
	t.exports = function (t, e) {
		var n = this.__data__,
			i = r(n, t);
		return i < 0 ? (++this.size, n.push([t, e])) : n[i][1] = e, this
	}
}, function (t, e, n) {
	var r = n(17)(n(9), "Map");
	t.exports = r
}, function (t, e, n) {
	var r = n(5);
	t.exports = function (t) {
		var e = r(this, t)["delete"](t);
		return this.size -= e ? 1 : 0, e
	}
}, function (t, e) {
	t.exports = function (t) {
		var e = typeof t;
		return "string" == e || "number" == e || "symbol" == e || "boolean" == e ? "__proto__" !== t : null === t
	}
}, function (t, e, n) {
	var r = n(5);
	t.exports = function (t) {
		return r(this, t).get(t)
	}
}, function (t, e, n) {
	var r = n(5);
	t.exports = function (t) {
		return r(this, t).has(t)
	}
}, function (t, e, n) {
	var r = n(5);
	t.exports = function (t, e) {
		var n = r(this, t),
			i = n.size;
		return n.set(t, e), this.size += n.size == i ? 0 : 1, this
	}
}, function (t, e, n) {
	var r = n(79);
	t.exports = function (t) {
		return null == t ? "" : r(t)
	}
}, function (t, e, n) {
	var r = n(8),
		i = n(80),
		o = n(6),
		a = n(7),
		s = 1 / 0,
		l = r ? r.prototype : undefined,
		h = l ? l.toString : undefined;
	t.exports = function u(t) {
		if ("string" == typeof t) return t;
		if (o(t)) return i(t, u) + "";
		if (a(t)) return h ? h.call(t) : "";
		var e = t + "";
		return "0" == e && 1 / t == -s ? "-0" : e
	}
}, function (t, e) {
	t.exports = function (t, e) {
		for (var n = -1, r = null == t ? 0 : t.length, i = Array(r); ++n < r;) i[n] = e(t[n], n, t);
		return i
	}
}, function (t, e, n) {
	var r = n(7),
		i = 1 / 0;
	t.exports = function (t) {
		if ("string" == typeof t || r(t)) return t;
		var e = t + "";
		return "0" == e && 1 / t == -i ? "-0" : e
	}
}, function (t, e, n) {
	"use strict";
	var r = n(1);
	r.a.Poly = r.a.Line.extend({})
}, function (t, e, n) {
	"use strict";
	var r = n(1);
	r.a.Rectangle = r.a.Poly.extend({
		_initMarkers: function () {
			var t = this._map,
				e = this._findCorners();
			this._markerGroup && this._markerGroup.clearLayers(), this._markerGroup = new L.LayerGroup, this._markerGroup._pmTempLayer = !0, t.addLayer(this._markerGroup), this._markers = [], this._markers[0] = e.map(this._createMarker, this), this._cornerMarkers = this._markers[0], this.options.snappable && this._initSnappableMarkers()
		},
		_createMarker: function (t, e) {
			var n = new L.Marker(t, {
				draggable: !this.options.preventVertexEdit,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return n._origLatLng = t, n._index = e, n._pmTempLayer = !0, n.on("dragstart", this._onMarkerDragStart, this), n.on("drag", this._onMarkerDrag, this), n.on("dragend", this._onMarkerDragEnd, this), n.on("pm:snap", this._adjustRectangleForMarkerSnap, this), this.options.preventMarkerRemoval || n.on("contextmenu", this._removeMarker, this), this._markerGroup.addLayer(n), n
		},
		_removeMarker: function () {
			return null
		},
		_onMarkerDragStart: function (t) {
			var e = t.target,
				n = this._findCorners();
			e._oppositeCornerLatLng = n[(e._index + 2) % 4], e._snapped = !1, this._layer.fire("pm:markerdragstart", {
				markerEvent: t
			})
		},
		_onMarkerDrag: function (t) {
			var e = t.target;
			e._index !== undefined && (e._snapped || this._adjustRectangleForMarkerMove(e))
		},
		_onMarkerDragEnd: function (t) {
			var e = this._findCorners();
			this._adjustAllMarkers(e), this._cornerMarkers.forEach(function (t) {
				delete t._oppositeCornerLatLng
			}), this._layer.setLatLngs(e), this._layer.redraw(), this._layer.fire("pm:markerdragend", {
				markerEvent: t
			}), this._fireEdit()
		},
		_adjustRectangleForMarkerMove: function (t) {
			L.extend(t._origLatLng, t._latlng);
			var e = t.getLatLng();
			this._layer.setBounds(L.latLngBounds(e, t._oppositeCornerLatLng)), this._adjustAdjacentMarkers(t), this._layer.redraw()
		},
		_adjustRectangleForMarkerSnap: function (t) {
			if (this.options.snappable) {
				var e = t.target;
				this._adjustRectangleForMarkerMove(e)
			}
		},
		_adjustAllMarkers: function (t) {
			t.length && 4 == t.length ? this._cornerMarkers.forEach(function (e, n) {
				e.setLatLng(t[n])
			}) : console.error("_adjustAllMarkers() requires an array of EXACTLY 4 LatLng coordinates")
		},
		_adjustAdjacentMarkers: function (t) {
			if (t && t.getLatLng && t._oppositeCornerLatLng) {
				var e = t.getLatLng(),
					n = t._oppositeCornerLatLng,
					r = [];
				this._findCorners().forEach(function (t) {
					t.equals(e) || t.equals(n) || r.push(t)
				});
				var i = 0;
				2 == r.length && this._cornerMarkers.forEach(function (t) {
					var o = t.getLatLng();
					o.equals(e) || o.equals(n) || (t.setLatLng(r[i]), i += 1)
				})
			} else console.error("_adjustAdjacentMarkers() requires a valid Marker object")
		},
		_findCorners: function () {
			var t = this._layer.getBounds();
			return [t.getNorthWest(), t.getNorthEast(), t.getSouthEast(), t.getSouthWest()]
		}
	})
}, function (t, e, n) {
	"use strict";
	var r = n(1);
	r.a.Circle = r.a.extend({
		initialize: function (t) {
			this._layer = t, this._enabled = !1
		},
		toggleEdit: function (t) {
			this.enabled() ? this.disable() : this.enable(t)
		},
		enabled: function () {
			return this._enabled
		},
		enable: function (t) {
			var e = this;
			L.Util.setOptions(this, t), this._map = this._layer._map, this.enabled() || this.disable(), this._enabled = !0, this._initMarkers(), this._layer.on("remove", function (t) {
				e.disable(t.target)
			})
		},
		disable: function () {
			var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this._layer;
			if (!this.enabled()) return !1;
			if (t.pm._dragging) return !1;
			t.pm._enabled = !1, t.pm._helperLayers.clearLayers(), t.off("mousedown"), t.off("mouseup");
			var e = t._path ? t._path : this._layer._renderer._container;
			return L.DomUtil.removeClass(e, "leaflet-pm-draggable"), this._layerEdited && this._layer.fire("pm:update", {}), this._layerEdited = !1, !0
		},
		_initMarkers: function () {
			var t = this._map;
			this._helperLayers && this._helperLayers.clearLayers(), this._helperLayers = (new L.LayerGroup).addTo(t);
			var e = this._layer.getLatLng(),
				n = this._layer._radius,
				r = this._getLatLngOnCircle(e, n);
			this._centerMarker = this._createCenterMarker(e), this._outerMarker = this._createOuterMarker(r), this._markers = [this._centerMarker, this._outerMarker], this._createHintLine(this._centerMarker, this._outerMarker), this.options.snappable && this._initSnappableMarkers()
		},
		_getLatLngOnCircle: function (t, e) {
			var n = this._map.project(t),
				r = L.point(n.x + e, n.y);
			return this._map.unproject(r)
		},
		_resizeCircle: function () {
			this._syncHintLine(), this._syncCircleRadius()
		},
		_moveCircle: function (t) {
			var e = t.latlng;
			this._layer.setLatLng(e);
			var n = this._layer._radius,
				r = this._getLatLngOnCircle(e, n);
			this._outerMarker.setLatLng(r), this._syncHintLine(), this._layer.fire("pm:centerplaced", {
				layer: this._layer,
				latlng: e
			})
		},
		_onMarkerDragStart: function (t) {
			this._layer.fire("pm:markerdragstart", {
				markerEvent: t
			})
		},
		_onMarkerDragEnd: function (t) {
			this._fireEdit(), this._layer.fire("pm:markerdragend", {
				markerEvent: t
			})
		},
		_syncCircleRadius: function () {
			var t = this._centerMarker.getLatLng(),
				e = this._outerMarker.getLatLng(),
				n = t.distanceTo(e);
			this._layer.setRadius(n)
		},
		_syncHintLine: function () {
			var t = this._centerMarker.getLatLng(),
				e = this._outerMarker.getLatLng();
			this._hintline.setLatLngs([t, e])
		},
		_createHintLine: function (t, e) {
			var n = t.getLatLng(),
				r = e.getLatLng();
			this._hintline = L.polyline([n, r], this.options.hintlineStyle), this._hintline._pmTempLayer = !0, this._helperLayers.addLayer(this._hintline)
		},
		_createCenterMarker: function (t) {
			var e = this._createMarker(t);
			return L.DomUtil.addClass(e._icon, "leaflet-pm-draggable"), e.on("move", this._moveCircle, this), e
		},
		_createOuterMarker: function (t) {
			var e = this._createMarker(t);
			return this.options.preventVertexEdit && e.dragging.disable(), e.on("move", this._resizeCircle, this), e
		},
		_createMarker: function (t) {
			var e = new L.Marker(t, {
				draggable: !0,
				icon: L.divIcon({
					className: "marker-icon"
				})
			});
			return e._origLatLng = t, e._pmTempLayer = !0, e.on("dragstart", this._onMarkerDragStart, this), e.on("dragend", this._onMarkerDragEnd, this), this._helperLayers.addLayer(e), e
		},
		_fireEdit: function () {
			this._layer.fire("pm:edit"), this._layerEdited = !0
		}
	})
}, function (t, e) {}, function (t, e) {}]);