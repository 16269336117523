define("ember-steps/-private/state-machine/-base", ["exports", "tracked-maps-and-sets", "@glimmer/tracking", "@ember/debug", "@ember/utils", "ember-steps/-private/step-node"], function (_exports, _trackedMapsAndSets, _tracking, _debug, _utils, _stepNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Keeps track of the order of the steps in the step manager, as well as
   * the current step.
   *
   * @class BaseStateMachine
   * @private
   * @hide
   */
  let BaseStateMachine = _exports.default = (_class = class BaseStateMachine {
    constructor(initialStep) {
      /** @type {TrackedSet<StepNode>} **/
      _initializerDefineProperty(this, "nodes", _descriptor, this);
      /** @type {string} **/
      _initializerDefineProperty(this, "currentStep", _descriptor2, this);
      this.currentStep = initialStep;
    }
    get length() {
      return this.nodes.size;
    }
    get firstStepName() {
      const firstNode = this.nodeArray[0];
      return firstNode && firstNode.name;
    }
    get currentStepNode() {
      return this.nodeArray.find(stepNode => stepNode.name === this.currentStep);
    }
    get nodeArray() {
      return [...this.nodes];
    }
    addStep(stepComponent) {
      const node = new _stepNode.default(this, stepComponent);
      this.nodes.add(node);
      if (typeof this.currentStep === 'undefined') {
        this.currentStep = stepComponent.name;
      }
    }
    removeStep(stepComponent) {
      const node = this.nodeArray.find(step => step.component === stepComponent);
      (true && !(node) && (0, _debug.assert)(`Could not find a step with name: ${node.name}`, node));
      this.nodes.delete(node);
    }
    updateStepNode(name, field, value) {
      const node = this.nodeArray.find(node => node.name === name);
      (true && !(node) && (0, _debug.assert)(`"${name}" does not match an existing step`, node));
      node[field] = value;
    }
    pickNext() {
      throw new Error('Must implement method');
    }
    pickPrevious() {
      throw new Error('Must implement method');
    }
    activate(step) {
      const name = step instanceof _stepNode.default ? step.name : step;
      (true && !(!(0, _utils.isNone)(step)) && (0, _debug.assert)('No step name was provided', !(0, _utils.isNone)(step)));
      (true && !(this.nodeArray.map(node => node.name).includes(name)) && (0, _debug.assert)(`"${name}" does not match an existing step`, this.nodeArray.map(node => node.name).includes(name)));
      this.currentStep = name;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "nodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedSet();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentStep", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});