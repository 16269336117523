define("pagination-pager/components/page-item", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: 'li',
    classNameBindings: ['isActive:active', 'disabled'],
    seperator: '…',
    selected: null,
    url: (0, _object.computed)('firstPage', 'firstPageUrlTemplate', 'page', 'urlTemplate', function () {
      let urlTemplate = this.urlTemplate;
      let current = this.page;
      let firstPage = this.firstPage;
      let firstPageUrlTemplate = this.firstPageUrlTemplate;
      if (firstPageUrlTemplate && current === firstPage) {
        return firstPageUrlTemplate;
      }
      urlTemplate = urlTemplate.replace('{current}', current);
      return urlTemplate;
    }),
    isActive: (0, _object.computed)('page', 'selected', function () {
      try {
        return this.page === Number(this.selected);
      } catch (e) {
        return false;
      }
    }),
    isDots: (0, _object.computed)('page', 'seperator', function () {
      let seperator = this.seperator;
      let page = this.page;
      return page === seperator;
    }),
    actions: {
      select() {
        if (this.disabled) {
          return;
        }
        let last = this.selected;
        let page = this.page;
        if (page !== last) {
          this.pageSet(page, last);
        }
      }
    }
  });
});