define("ember-validated-form/components/validated-input/types/select", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select
    class={{class-list
      (if
        (macroCondition (macroGetOwnConfig "isUikit"))
        (class-list
          "uk-select"
          (if @isValid "uk-form-success")
          (if @isInvalid "uk-form-danger")
        )
      )
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap"))
        (class-list
          "form-control" (if @isValid "is-valid") (if @isInvalid "is-invalid")
        )
      )
    }}
    name={{@name}}
    id={{@inpudId}}
    disabled={{@disabled}}
    multiple={{@multiple}}
    {{on "change" this.onUpdate}}
    {{on "blur" this.onBlur}}
    ...attributes
  >
    {{#if @prompt}}
      <option
        value=""
        disabled={{not @promptIsSelectable}}
        selected={{not @value}}
      >{{@prompt}}</option>
    {{/if}}
  
    {{#if this.hasGrouping}}
      {{#each this.optionGroups as |optionGroup|}}
        <optgroup label={{optionGroup.groupName}}>
          {{#each optionGroup.options as |opt|}}
            <option selected={{eq opt.id @value}} value={{opt.id}}>
              {{opt.label}}
            </option>
          {{/each}}
        </optgroup>
      {{/each}}
    {{else}}
      {{#each this.normalizedOptions as |opt|}}
        <option selected={{eq opt.id @value}} value={{opt.id}}>
          {{opt.label}}
        </option>
      {{/each}}
    {{/if}}
  </select>
  */
  {
    "id": "56jY/GRl",
    "block": "[[[11,\"select\"],[16,0,[28,[37,0],[[27],[28,[37,0],[\"form-control\",[52,[30,1],\"is-valid\"],[52,[30,2],\"is-invalid\"]],null]],null]],[16,3,[30,3]],[16,1,[30,4]],[16,\"disabled\",[30,5]],[16,\"multiple\",[30,6]],[17,7],[4,[38,2],[\"change\",[30,0,[\"onUpdate\"]]],null],[4,[38,2],[\"blur\",[30,0,[\"onBlur\"]]],null],[12],[1,\"\\n\"],[41,[30,8],[[[1,\"    \"],[10,\"option\"],[14,2,\"\"],[15,\"disabled\",[28,[37,3],[[30,9]],null]],[15,\"selected\",[28,[37,3],[[30,10]],null]],[12],[1,[30,8]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasGrouping\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"optionGroups\"]]],null]],null],null,[[[1,\"      \"],[10,\"optgroup\"],[15,\"label\",[30,11,[\"groupName\"]]],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,11,[\"options\"]]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,\"selected\",[28,[37,6],[[30,12,[\"id\"]],[30,10]],null]],[15,2,[30,12,[\"id\"]]],[12],[1,\"\\n            \"],[1,[30,12,[\"label\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[12]],null],[1,\"      \"],[13],[1,\"\\n\"]],[11]],null]],[]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"normalizedOptions\"]]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,\"selected\",[28,[37,6],[[30,13,[\"id\"]],[30,10]],null]],[15,2,[30,13,[\"id\"]]],[12],[1,\"\\n        \"],[1,[30,13,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[13]],null]],[]]],[13]],[\"@isValid\",\"@isInvalid\",\"@name\",\"@inpudId\",\"@disabled\",\"@multiple\",\"&attrs\",\"@prompt\",\"@promptIsSelectable\",\"@value\",\"optionGroup\",\"opt\",\"opt\"],false,[\"class-list\",\"if\",\"on\",\"not\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/select.hbs",
    "isStrictMode": false
  });

  /**
   * This component is heavily inspired by the ember-one-way-select addon.
   * https://github.com/DockYard/ember-one-way-select
   * Our implementation is slightly simpler, since it does not support
   * the block syntax for options.
   */
  let SelectComponent = _exports.default = (_class = class SelectComponent extends _component2.default {
    get hasPreGroupedOptions() {
      return this.args.options[0]?.groupName && Array.isArray(this.args.options[0]?.options);
    }
    get hasGrouping() {
      return this.hasPreGroupedOptions || this.args.groupLabelPath;
    }
    get normalizedOptions() {
      // normalize options to common data structure, only for rendering
      return this.args.options.map(opt => this.normalize(opt));
    }
    normalize(option) {
      if (typeof option !== "object") {
        return {
          id: option,
          label: option
        };
      }
      const valuePath = this.args.optionValuePath ?? this.args.optionTargetPath;
      const labelPath = this.args.optionLabelPath;
      return {
        id: valuePath ? option[valuePath] : option.id,
        label: labelPath ? option[labelPath] : option.label
      };
    }
    get optionGroups() {
      const groupLabelPath = this.args.groupLabelPath;
      if (!groupLabelPath) {
        return this.args.options;
      }
      const groups = [];
      this.args.options.forEach(item => {
        const label = (0, _object.get)(item, groupLabelPath);
        if (label) {
          let group = groups.find(group => group.groupName === label);
          if (!group) {
            group = _object.default.create({
              groupName: label,
              options: []
            });
            groups.push(group);
          }
          group.options.push(this.normalize(item));
        } else {
          groups.push(item);
        }
      });
      return groups;
    }
    findOption(target) {
      const targetPath = this.args.optionTargetPath;
      const valuePath = this.args.optionValuePath || targetPath;
      const getValue = item => {
        if (valuePath) {
          return String(item[valuePath]);
        }
        if (typeof item === "object") {
          return String(item.id);
        }
        return String(item);
      };
      let options = this.args.options;

      //flatten pre grouped options
      if (this.hasPreGroupedOptions) {
        options = options.flatMap(group => group.options);
      }

      // multi select
      if (this.args.multiple) {
        const selectedValues = Array.prototype.filter.call(target.options, option => option.selected).map(option => option.value);
        const foundOptions = options.filter(item => {
          return selectedValues.includes(getValue(item));
        });
        if (targetPath) {
          return foundOptions.map(item => item[targetPath]);
        }
        return foundOptions;
      }

      //single select
      const foundOption = options.find(item => getValue(item) === target.value);

      // If @promptIsSelectable is set to true, foundOption in this case will be undefined.
      if (targetPath && foundOption) {
        return foundOption[targetPath];
      }
      return foundOption;
    }
    onUpdate(event) {
      if (this.args.update) {
        this.args.update(this.findOption(event.target));
      }
    }
    onBlur(event) {
      if (this.args.setDirty) {
        this.args.setDirty(event.target.value);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectComponent);
});