define("liquid-fire/templates/components/liquid-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "406NCR9R",
    "block": "[[[18,1,[[30,0]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-container.hbs",
    "isStrictMode": false
  });
});