define("liquid-fire/templates/components/liquid-spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tTPhZbM7",
    "block": "[[[6,[39,0],null,[[\"didMeasure\"],[[28,[37,1],[[30,0],[30,0,[\"sizeChanged\"]]],null]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"liquid-measured\",\"action\",\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-spacer.hbs",
    "isStrictMode": false
  });
});