define("ember-leaflet/templates/leaflet-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZaCNsY7a",
    "block": "[[[44,[[28,[37,1],null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"rectangle\",\"wmsTile\",\"geoJSON\"],[[50,\"tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"wms-tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"marker-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"circle-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"circle-marker-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"image-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"video-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"polyline-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"polygon-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"geojson-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"rectangle-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"wms-tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"geojson-layer\",0,null,[[\"parentComponent\"],[[30,0]]]]]]]],[[[1,\"\\n\"],[41,[30,0,[\"emberLeaflet\",\"components\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"emberLeaflet\",\"components\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],[[30,1]],[[\"key\",\"value\",\"onChange\"],[[30,2,[\"as\"]],[50,[30,2,[\"name\"]],0,null,[[\"parentComponent\"],[[30,0]]]],[30,0,[\"mergeComponents\"]]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n    \"],[18,3,[[30,0,[\"mergedComponents\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]]]],[1]]],[1,\"\\n\"]],[\"components\",\"c\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"each\",\"-track-array\",\"ember-leaflet-assign-to\",\"yield\"]]",
    "moduleName": "ember-leaflet/templates/leaflet-map.hbs",
    "isStrictMode": false
  });
});