define("ember-steps/components/step-manager/step", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/utils", "@ember/debug", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _utils, _debug, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isActive}}
    {{yield
      (hash
        hasNext=this.hasNext
        hasPrevious=this.hasPrevious
      )
    }}
  {{else if (has-block "inverse")}}
    {{yield to="inverse"}}
  {{/if}}
  
  */
  {
    "id": "KezsGIfJ",
    "block": "[[[41,[30,0,[\"isActive\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"hasNext\",\"hasPrevious\"],[[30,0,[\"hasNext\"]],[30,0,[\"hasPrevious\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],null]],[]]]],[\"&default\",\"&else\"],false,[\"if\",\"yield\",\"hash\",\"has-block\"]]",
    "moduleName": "ember-steps/components/step-manager/step.hbs",
    "isStrictMode": false
  });
  /**
   * ```hbs
   * <w.Step @name="first">
   *   <h1>First Step</h1>
   * </w.Step>
   * ```
   *
   * @class StepComponent
   * @yield {Hash} step Step Properties
   * @yield {boolean} step.hasPrevious Whether this step has a "previous" step
   * @yield {boolean} step.hasNext Whether this step has a "next" step
   */
  let StepComponent = _exports.default = (_class = class StepComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /** Set directly by manager through JS **/
      _initializerDefineProperty(this, "transitions", _descriptor, this);
      this.args['register-step'](this);
    }
    willDestroy(...args) {
      this.args['remove-step'](this);
      super.willDestroy(...args);
    }

    /**
     * @type {string|number|Symbol}
     * @private
     */
    get name() {
      const initialName = !(0, _utils.isNone)(this.args.name) ? this.args.name : (0, _internals.guidFor)(this);
      if (this._hasSetName) {
        (true && !(this._initialName === initialName) && (0, _debug.assert)('The `name` property should never change', this._initialName === initialName));
      }
      this._hasSetName = true;
      this._initialName = initialName;
      return initialName;
    }

    /**
     * Whether this state is currently the active one
     * @property {boolean} isActive
     * @private
     */
    get isActive() {
      return this.args.currentStep === this.name;
    }
    get hasNext() {
      return (0, _utils.isPresent)(this.transitions.pickNext(this.name));
    }
    get hasPrevious() {
      return (0, _utils.isPresent)(this.transitions.pickPrevious(this.name));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "transitions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StepComponent);
});