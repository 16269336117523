define("liquid-fire/templates/components/illiquid-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "phVCvA9r",
    "block": "[[[18,1,[[33,1]]]],[\"&default\"],false,[\"yield\",\"_fixedModel\"]]",
    "moduleName": "liquid-fire/templates/components/illiquid-model.hbs",
    "isStrictMode": false
  });
});