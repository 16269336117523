define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zrNLUzx1",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[28,[37,4],[[33,5],[30,1,[\"value\"]]],null],[[[6,[39,6],null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[30,1],[28,[37,7],[[30,0],\"childDidRender\"],null],[33,8]]],[[\"default\"],[[[[18,2,[[30,1,[\"value\"]]]]],[]]]]]],[]],null]],[1]],null]],[\"version\",\"&default\"],false,[\"each\",\"-track-array\",\"versions\",\"if\",\"lf-or\",\"renderWhenFalse\",\"liquid-child\",\"action\",\"class\",\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-versions.hbs",
    "isStrictMode": false
  });
});