define("ember-leaflet/templates/div-overlayable", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "moFEmL6Z",
    "block": "[[[18,1,[[28,[37,1],null,[[\"popup\",\"tooltip\"],[[50,\"popup-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"tooltip-layer\",0,null,[[\"parentComponent\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-leaflet/templates/div-overlayable.hbs",
    "isStrictMode": false
  });
});