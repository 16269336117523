define("ember-leaflet/templates/div-overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "EvoKNrZ2",
    "block": "[[[41,[51,[30,0,[\"isFastBoot\"]]],[[[41,[30,0,[\"shouldRender\"]],[[[40,[[[41,[30,0,[\"closePopup\"]],[[[1,\"        \"],[18,1,[[28,[37,5],[[30,0,[\"closePopup\"]]],null]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,1,null],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,3],[[30,0,[\"destinationElement\"]]],null]]],[]],null]],[]],null]],[\"&default\"],false,[\"unless\",\"if\",\"in-element\",\"-in-el-null\",\"yield\",\"fn\"]]",
    "moduleName": "ember-leaflet/templates/div-overlay.hbs",
    "isStrictMode": false
  });
});