define("ember-steps/components/step-manager", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/utils", "@ember/runloop", "@ember/debug", "@ember/object", "ember-steps/-private/state-machine/circular", "ember-steps/-private/state-machine/linear", "ember-steps/-private/step-node"], function (_exports, _component, _templateFactory, _component2, _tracking, _utils, _runloop, _debug, _object, _circular, _linear, _stepNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      Step=(component "step-manager/step"
        register-step=this.registerStepComponent
        remove-step=this.removeStepComponent
        update-step-node=this.updateStepNode
        currentStep=this.currentStep
        transitions=this.transitions
      )
      hasNextStep=this.hasNextStep
      hasPreviousStep=this.hasPreviousStep
      currentStep=this.currentStep
      steps=this.transitions.nodeArray
      transition-to=this.transitionTo
      transition-to-next=this.transitionToNext
      transition-to-previous=this.transitionToPrevious
    )
  }}
  
  */
  {
    "id": "kAHUJZF1",
    "block": "[[[18,1,[[28,[37,1],null,[[\"Step\",\"hasNextStep\",\"hasPreviousStep\",\"currentStep\",\"steps\",\"transition-to\",\"transition-to-next\",\"transition-to-previous\"],[[50,\"step-manager/step\",0,null,[[\"register-step\",\"remove-step\",\"update-step-node\",\"currentStep\",\"transitions\"],[[30,0,[\"registerStepComponent\"]],[30,0,[\"removeStepComponent\"]],[30,0,[\"updateStepNode\"]],[30,0,[\"currentStep\"]],[30,0,[\"transitions\"]]]]],[30,0,[\"hasNextStep\"]],[30,0,[\"hasPreviousStep\"]],[30,0,[\"currentStep\"]],[30,0,[\"transitions\",\"nodeArray\"]],[30,0,[\"transitionTo\"]],[30,0,[\"transitionToNext\"]],[30,0,[\"transitionToPrevious\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-steps/components/step-manager.hbs",
    "isStrictMode": false
  });
  /**
   * ```hbs
   * <StepManager as |w|>
   *   <w.Step @name="first">
   *     <h1>First Step</h1>
   *   </w.Step>
   *   <w.Step @name="second">
   *     <h1>Second Step</h1>
   *   </w.Step>
   * </StepManager>
   * ```
   *
   * @class StepManagerComponent
   * @yield {Hash} w Wizard Properties
   * @yield {StepComponent} w.Step The component to create steps
   * @yield {boolean} w.hasNextStep Whether or not the current step has a "next" step
   * @yield {boolean} w.hasPreviousStep Whether or not the current step has a "previous" step
   * @yield {string} w.currentStep Reflects the name of the active step
   * @yield {Array<StepNode>} w.steps The steps registered to the step manager
   * @yield {Action} w.transition-to Transition to a step by name
   * @yield {Action} w.transition-to-next Transition to the "next" step
   * @yield {Action} w.transition-to-previous Transition to the "previous" step
   */
  let StepManagerComponent = _exports.default = (_class = class StepManagerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /** @type {BaseStateMachine} state machine for transitions */
      _initializerDefineProperty(this, "transitions", _descriptor, this);
      const {
        initialStep,
        currentStep
      } = this.args;
      this._watchCurrentStep = (0, _utils.isPresent)(currentStep);
      const startingStep = (0, _utils.isNone)(initialStep) ? currentStep : initialStep;
      const StateMachine = this.linear ? _linear.default : _circular.default;
      this.transitions = new StateMachine(startingStep);
      (0, _runloop.schedule)('afterRender', this, function () {
        this._initialRegistrationComplete = true;
      });
    }

    /**
     * Whether to use a "Circular" or "Linear" state machine
     * @type {boolean}
     * @private
     */
    get linear() {
      return (0, _utils.isPresent)(this.args.linear) ? this.args.linear : true;
    }

    /**
     * Whether or not the current step has a "next" step
     * @type {boolean}
     */
    get hasNextStep() {
      return !(0, _utils.isNone)(this.transitions.pickNext());
    }

    /**
     * Whether or not the current step has a "previous" step
     * @type {boolean}
     */
    get hasPreviousStep() {
      return !(0, _utils.isNone)(this.transitions.pickPrevious());
    }

    /**
     * Reflects the name of the active step
     * @type {string}
     */
    get currentStep() {
      const newStep = typeof this.args.currentStep !== 'undefined' ? this.args.currentStep : this.transitions.firstStepName;
      const {
        currentStep
      } = this.transitions;
      if (this._watchCurrentStep && this._initialRegistrationComplete && newStep !== currentStep) {
        (0, _runloop.schedule)('actions', this, function () {
          this.transitionTo(newStep);
        });
      }
      return this.transitions.currentStep;
    }
    registerStepComponent(stepComponent) {
      stepComponent.transitions = this.transitions;
      (0, _runloop.schedule)('actions', () => {
        this.transitions.addStep(stepComponent);
      });
    }
    removeStepComponent(stepComponent) {
      (0, _runloop.schedule)('actions', () => {
        this.transitions.removeStep(stepComponent);
      });
    }
    updateStepNode(stepComponent, field, value) {
      const name = stepComponent.name;
      this.transitions.updateStepNode(name, field, value);
    }
    transitionTo(to) {
      const destination = to instanceof _stepNode.default ? to.name : to;
      if (to !== this.transitions.currentStep) {
        this.transitions.activate(destination);
        if (this.args.onTransition) {
          this.args.onTransition(destination);
        }
      }
    }
    transitionToNext() {
      const to = this.transitions.pickNext();
      (true && !(!(0, _utils.isNone)(to)) && (0, _debug.assert)('There is no next step', !(0, _utils.isNone)(to)));
      this.transitionTo(to);
    }
    transitionToPrevious() {
      const to = this.transitions.pickPrevious();
      (true && !(!(0, _utils.isNone)(to)) && (0, _debug.assert)('There is no previous step', !(0, _utils.isNone)(to)));
      this.transitionTo(to);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "transitions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerStepComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerStepComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeStepComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeStepComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStepNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStepNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToNext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToPrevious", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToPrevious"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StepManagerComponent);
});