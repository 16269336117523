define("liquid-fire/components/-lf-get-outlet-state", ["exports", "@ember/component", "liquid-fire/templates/components/get-outlet-state"], function (_exports, _component, _getOutletState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _getOutletState.default
  });
});