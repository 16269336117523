define("ember-can/helpers/can", ["exports", "@ember/component/helper", "@ember/service", "@ember/object/observers", "@ember/object"], function (_exports, _helper, _service, _observers, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    can: (0, _service.inject)(),
    ability: null,
    propertyName: null,
    compute([abilityString, model], properties) {
      let {
        abilityName,
        propertyName
      } = this.can.parse(abilityString);
      let ability = this.can.abilityFor(abilityName, model, properties);
      propertyName = ability.parseProperty(propertyName);
      this._removeAbilityObserver();
      this._addAbilityObserver(ability, propertyName);
      return (0, _object.get)(ability, propertyName);
    },
    destroy() {
      this._removeAbilityObserver();
      return this._super(...arguments);
    },
    _addAbilityObserver(ability, propertyName) {
      (0, _object.setProperties)(this, {
        ability,
        propertyName
      });
      (0, _observers.addObserver)(this, `ability.${propertyName}`, this, 'recompute');
    },
    _removeAbilityObserver() {
      (0, _observers.removeObserver)(this, `ability.${this.propertyName}`, this, 'recompute');
      this.ability && this.ability.destroy();
      (0, _object.setProperties)(this, {
        ability: null,
        propertyName: null
      });
    }
  });
});