define("ember-leaflet/components/base-layer", ["exports", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/component", "@ember/application", "@ember/runloop", "@ember/array", "@ember/string", "ember-composability-tools", "ember-invoke-action"], function (_exports, _debug, _object, _computed, _component, _application, _runloop, _array, _string, _emberComposabilityTools, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global L */

  const leaf = typeof L === 'undefined' ? {} : L;
  var _default = _exports.default = _component.default.extend(_emberComposabilityTools.ChildMixin, _emberInvokeAction.InvokeActionMixin, {
    tagName: '',
    L: leaf,
    fastboot: (0, _object.computed)(function () {
      let owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: (0, _computed.and)('fastboot', 'fastboot.isFastBoot'),
    concatenatedProperties: ['leafletOptions', 'leafletRequiredOptions', 'leafletEvents', 'leafletProperties'],
    leafletOptions: Object.freeze(['pane', 'attribution']),
    leafletEvents: Object.freeze(['add', 'remove']),
    createLayer() {
      (true && !(false) && (0, _debug.assert)('BaseLayer\'s `createLayer` should be overriden.'));
    },
    didCreateLayer() {},
    willDestroyLayer() {},
    /*
     * Method called by parent when the layer needs to setup
     */
    didInsertParent() {
      // Check for fastBoot
      if (this.get('isFastBoot')) {
        return;
      }
      this._layer = this.createLayer();
      this._addObservers();
      this._addEventListeners();
      if (this.get('parentComponent')) {
        this.addToContainer();
      }
      this.didCreateLayer();
    },
    /*
     * Default logic for adding the layer to the container
     */
    addToContainer() {
      this.get('parentComponent')._layer.addLayer(this._layer);
    },
    /*
     * Method called by parent when the layer needs to teardown
     */
    willDestroyParent() {
      // Check for fastBoot
      if (this.get('isFastBoot')) {
        return;
      }
      this.willDestroyLayer();
      this._removeEventListeners();
      this._removeObservers();
      if (this.get('parentComponent') && this._layer) {
        this.removeFromContainer();
      }
      delete this._layer;
    },
    /*
     * Default logic for removing the layer from the container
     */
    removeFromContainer() {
      this.get('parentComponent')._layer.removeLayer(this._layer);
    },
    options: (0, _object.computed)(function () {
      let leafletOptions = this.get('leafletOptions');
      let options = {};
      leafletOptions.forEach(optionName => {
        if (this.get(optionName) !== undefined) {
          options[optionName] = this.get(optionName);
        }
      });
      return options;
    }),
    leafletRequiredOptions: (0, _array.A)(),
    requiredOptions: (0, _object.computed)(function () {
      let leafletRequiredOptions = this.get('leafletRequiredOptions');
      let options = [];
      leafletRequiredOptions.forEach(optionName => {
        (true && !(this.get(optionName)) && (0, _debug.assert)(`\`${optionName}\` is a required option but its value was \`${this.get(optionName)}\``, this.get(optionName)));
        options.push(this.get(optionName));
      });
      return options;
    }),
    usedLeafletEvents: (0, _object.computed)('leafletEvents', function () {
      return this.get('leafletEvents').filter(eventName => {
        let methodName = `_${eventName}`;
        let actionName = `on${(0, _string.classify)(eventName)}`;
        return this.get(methodName) !== undefined || this.get(actionName) !== undefined;
      });
    }),
    _addEventListeners() {
      this._eventHandlers = {};
      this.get('usedLeafletEvents').forEach(eventName => {
        let actionName = `on${(0, _string.classify)(eventName)}`;
        let methodName = `_${eventName}`;
        // create an event handler that runs the function inside an event loop.
        this._eventHandlers[eventName] = function (e) {
          this._onActions = () => {
            // try to invoke/send an action for this event
            this.invokeAction(actionName, e);
            // allow classes to add custom logic on events as well
            if (typeof this[methodName] === 'function') {
              this[methodName](e);
            }
          };
          (0, _runloop.scheduleOnce)('actions', this, this._onActions);
        };
        this._layer.addEventListener(eventName, this._eventHandlers[eventName], this);
      });
    },
    _removeEventListeners() {
      if (this._eventHandlers) {
        this.get('usedLeafletEvents').forEach(eventName => {
          this._layer.removeEventListener(eventName, this._eventHandlers[eventName], this);
          delete this._eventHandlers[eventName];
        });
      }
    },
    leafletProperties: (0, _array.A)(),
    _addObservers() {
      this._observers = {};
      this.get('leafletProperties').forEach(propExp => {
        let [property, leafletProperty, ...params] = propExp.split(':');
        if (!leafletProperty) {
          leafletProperty = `set${(0, _string.classify)(property)}`;
        }
        let objectProperty = property.replace(/\.\[]/, ''); // allow usage of .[] to observe array changes

        this._observers[property] = function () {
          let value = this.get(objectProperty);
          (true && !(!!this._layer[leafletProperty]) && (0, _debug.assert)(`${this.constructor} must have a ${leafletProperty} function.`, !!this._layer[leafletProperty]));
          let propertyParams = params.map(p => this.get(p));
          this._layer[leafletProperty].call(this._layer, value, ...propertyParams);
        };
        this.addObserver(property, this, this._observers[property]);
      });
    },
    _removeObservers() {
      if (this._observers) {
        this.get('leafletProperties').forEach(propExp => {
          let [property] = propExp.split(':');
          this.removeObserver(property, this, this._observers[property]);
          delete this._observers[property];
        });
      }
    }
  });
});